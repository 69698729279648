import axios from 'axios';
import react from 'react';
import './add_new_commands.css';

class ChannelPools extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        channel_id: this.props.channel_id,
        pools: [],
        alert: {},
        redeem_command: '',
        channel_name: ''
      }
      this.update_redeem_amount = this.update_redeem_amount.bind(this);
    }

    componentDidMount() {
        axios.get(`https://scurvybot.com/api/channel_pools?channel_id=${this.state.channel_id}`)
        .then((res) => this.setState({pools: res.data.message}))
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
        axios.get(`https://scurvybot.com/api/get_channel_name?channel_id=${this.state.channel_id}`)
        .then((res) => this.setState({channel_name: res.data.message}))
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
    }
    
    update_redeem_amount(e) {
        this.setState({redeem_command: `!redeem ${e.target.value}`});
    }

    render () {
        let view_pools = this.state.pools.length >= 1 ? this.state.pools : [{pool_name: 'N/A', token_name: 'N/A', balance: 'N/A', redemption_rate: 'N/A', pool_id: 'N/A'}];
        let redeem_command = this.state.redeem_command ? this.state.redeem_command : '';
        let channel_name = this.state.channel_name.length > 0 ? this.state.channel_name : '';
        channel_name = channel_name === 'NULL' ? '' : channel_name;
        if (channel_name.length > 0) channel_name += '\'s';

        return (
            <div>
                <div className="header">
                    <a href="https://scurvybot.com/"><img className="logo" alt="scurvy-logo" src="./logo.png"/></a>
                    <p className="title">Scurvy Bot</p>
                </div>
                <div className="view_pools-page">
                    <div className="view_pools-list">
                        <h3 className="view_pools-title main-title">{channel_name} Pools</h3>
                        <table className="view_pools-table">
                            <tr className="view_pools-rows">
                                <th className="view_pools-headings">Name</th>
                                <th className="view_pools-headings">Token</th>
                                <th className="view_pools-headings">Rate</th>
                                <th className="view_pools-headings">Pool ID</th>
                                <th className="view_pools-headings">Create Command</th>
                            </tr>
                            {view_pools.map((x, index) => {
                                return (
                                    <tr className="view_pools-rows" key={index}>
                                        <td className="view_pools-datacells">{x.pool_name}</td>
                                        <td className="view_pools-datacells">{x.token}</td>
                                        <td className="view_pools-datacells">{x.redemption_rate}</td>
                                        <td className="view_pools-datacells">{x.pool_id}</td>
                                        <td className="view_pools-datacells">
                                            <label for="amount">Amount</label>
                                            <input type="number" id="amount" max={x.balance} onChange={this.update_redeem_amount}></input>
                                            <p>{redeem_command} {x.pool_id}</p>
                                            <p>Deposit memo: {this.state.channel_id}-{x.pool_id}</p>
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChannelPools;