import axios from 'axios';
import react from 'react';
import './poll.css';
import Login from './login'

class Poll extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        title: '',
        choices: [{}],
        duration: 300,
        channel_points_voting_enabled: 'on',
        channel_points_per_vote: 1,
        choice_1: '',
        choice_2: '',
        choice_3: '',
        choice_4: '',
        choice_5: '',
        alert: {}
      }
      this.set_choice_1 = this.set_choice_1.bind(this);
      this.set_choice_2 = this.set_choice_2.bind(this);
      this.set_choice_3 = this.set_choice_3.bind(this);
      this.set_choice_4 = this.set_choice_4.bind(this);
      this.set_choice_5 = this.set_choice_5.bind(this);
      this.set_title = this.set_title.bind(this);
      this.set_duration = this.set_duration.bind(this);
      this.set_choices = this.set_choices.bind(this);
      this.set_channel_points = this.set_channel_points.bind(this);
      this.set_voting_enabled = this.set_voting_enabled.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.create_poll = this.create_poll.bind(this);
    }

    set_title (e) {
        this.setState({title: e.target.value})
    }

    set_choice_1 (e) {
        this.setState({choice_1: e.target.value})
    }

    set_choice_2 (e) {
        this.setState({choice_2: e.target.value})
    }

    set_choice_3 (e) {
        this.setState({choice_3: e.target.value})
    }

    set_choice_4 (e) {
        this.setState({choice_4: e.target.value})
    }

    set_choice_5 (e) {
        this.setState({choice_5: e.target.value})
    }

    set_channel_points (e) {
        this.setState({channel_points_per_vote: e.target.value})
    }

    set_voting_enabled (e) {
        this.setState({channel_points_voting_enabled: e.target.value}, () => {
            console.log(this.state.channel_points_voting_enabled)
        });
    }

    set_duration (e) {
        this.setState({duration: e.target.value});
    }

    set_choices () {
        return new Promise((resolve, reject) => {
            let array = ['choice_1', 'choice_2', 'choice_3', 'choice_4', 'choice_5'];
            let choices_array = [];
            array.map((x, index) => {
                if (this.state[x] !== '') {
                    choices_array.push({title: this.state[x]});
                }
                try {
                    if (index === array.length-1) {
                        this.setState({choices: choices_array}, () => {
                            resolve();
                        })
                    }
                } catch (e) {
                    this.setState({alert: {bool: true, type: 'failed', message: 'An error occurred creating your poll. Please try again.'}}, () => {
                        this.pass_alert();
                    })
                    reject(e);
                }
            })
        })
    }

    create_poll (e) {
        e.preventDefault();
        this.set_choices()
        .then(() => {
            axios.post('https://scurvybot.com/api/create_poll', {
                title: this.state.title,
                choices: this.state.choices,
                duration: this.state.duration,
                channel_points_voting_enabled: this.state.channel_points_voting_enabled,
                channel_points_per_vote: this.state.channel_points_per_vote,
            })
            .then(() => {
                this.setState({alert: {bool: true, type: 'success', message: 'Poll created successfully.'}}, () => {
                    this.pass_alert();
                })
            })
            .catch((err) => {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert();
                })
            })
        })
        .catch((e) => {
            this.setState({alert: {bool: true, type: 'failed', message: e.response.data.message}}, () => {
                this.pass_alert();
            })
        });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    render () {
        let enabled_radio_true = this.state.channel_points_voting_enabled === 'on' ? true : false;
        let enabled_radio_false = this.state.channel_points_voting_enabled === 'on' ? false : true;
        let disabled = this.state.channel_points_voting_enabled === 'on' ? false : true;
        let required = this.state.channel_points_voting_enabled === 'on' ? true : false;

        return (
            <div className="poll-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="poll-title main-title">Create A Poll</h3>
                <div className="poll-form">
                    <form id="poll-form" onSubmit={this.create_poll}>
                        <label for="poll-cost" className="poll-settings-label">Poll Question: </label>
                        <input id="poll-cost" className="poll-settings-input" type="text" name="poll-cost"  placeholder='The question to ask stream.' value={this.state.title} onChange={this.set_title} required/>
                        <br/><br/>
                        <label for="percentage" className="poll-settings-label">Choices: </label>
                        <br/>
                        <input type="text" className="poll-settings-input poll-choice" id="percentage" name="percentage" placeholder='1st choice' value={this.state.choice_1} onChange={this.set_choice_1} required/>
                        <input type="text" className="poll-settings-input poll-choice" id="percentage" name="percentage" placeholder='2nd choice' value={this.state.choice_2} onChange={this.set_choice_2}/>
                        <input type="text" className="poll-settings-input poll-choice" id="percentage" name="percentage" placeholder='3rd choice' value={this.state.choice_3} onChange={this.set_choice_3}/>
                        <input type="text" className="poll-settings-input poll-choice" id="percentage" name="percentage" placeholder='4th choice' value={this.state.choice_4} onChange={this.set_choice_4}/>
                        <input type="text" className="poll-settings-input poll-choice" id="percentage" name="percentage" placeholder='5th choice' value={this.state.choice_5} onChange={this.set_choice_5}/>
                        <br/><br/>
                        <label for="cooldown" className="poll-settings-label">Duration (seconds): </label>
                        <input type="number" className="poll-settings-input" id="cooldown" name="cooldown" min="1" max="1800" placeholder={this.state.duration} value={this.state.duration} onChange={this.set_duration} required/>
                        <br/><br/>
                        <label for="enable" className="poll-settings-label" required>Enable Channel Voting</label>
                        <div name="enable">
                            <input type="radio" className="poll-settings-input" id="form-enable" name="enable" onChange={this.set_voting_enabled} value='on' checked={enabled_radio_true}></input>
                            <label className="radio-labels">Enable</label>
                            <input type="radio" className="poll-settings-input" id="form-disable" name="enable" onChange={this.set_voting_enabled} value='off' checked={enabled_radio_false}></input>
                            <label className="radio-labels">Disable</label>
                        </div>
                        <br/>
                        <label for="cost" >Cost per vote</label>
                        <input type="number" className="poll-settings-input" id="form-cost" name="cost" min="1" max="1000000" onChange={this.set_channel_points} value={this.state.channel_points_per_vote} default={this.state.channel_points_per_vote} disabled={disabled} required={required}/>
                        <br/><br/>
                        <button type="submit" className="token-form-button">Create Poll</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Poll;