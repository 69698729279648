import react from 'react';
import './tools-submenu.css';

class SubmenuTools extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        active_submenu: this.props.active_submenu
      }
      this.display_features = this.display_features.bind(this);
      this.display_commands = this.display_commands.bind(this);
      this.display_timed_commands = this.display_timed_commands.bind(this);
      this.reset_menu = this.reset_menu.bind(this);
    }

    display_features () {
        this.props.pass_features(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_commands () {
        this.props.pass_commands(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_timed_commands () {
        this.props.pass_timed_commands(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    reset_menu () {
        this.props.reset_menu();
    } 

    render () {
        return (
            <div className="sub-menus">
                <div className="commands sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_commands}>Commands</h4>
                </div>
                <div className="timed-commands sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_timed_commands}>Timed Commands</h4>
                </div>
                <div className="features sub-menu-items" >
                    <h4 className="sub-menu-item-content" onClick={this.display_features}>Built-in Features</h4>
                </div>
                <div className="overlays sub-menu-items" >
                    <h4 className="sub-menu-item-content grey">Overlays (tbd)</h4>
                </div>
            </div>
        )
    }
}

export default SubmenuTools;