import react from 'react';
import './App.css';
import Sidebar from './components/sidebar';
import Homepage from './components/homepage';
import ChannelToken from './components/channel_token'
import Leaderboard from './components/leaderboard';
import ViewPools from './components/view_pools';
import EditPool from './components/edit_pools';
import CreatePool from './components/create_pool';
import Features from './components/features';
import Dig from './components/dig';
import Chests from './components/chests'
import Commands from './components/commands';
import EditCommand from './components/edit_command';
import AddCommand from './components/add_new_command'
import TimedCommands from './components/timed-commands';
import EditTimedCommands from './components/edit_timed_commands';
import AddTimedCommand from './components/add_new_timed_command';
import Withdraw from './components/withdraw'
import Assets from './components/assets'
import Settings from './components/settings'
import Support from './components/support'
import Transactions from './components/transactions';
import Banned from './components/banned';
import AddBanned from './components/add_banned';
import Alert from './components/alert';
import Shoutout from './components/shoutout';
import Poll from './components/poll';
import ChannelPools from './components/channel_pools';
import Privacy from './components/privacy'
import Terms from './components/terms'
import ChannelCommands from './components/channel_commands';
import Tip from './components/tip';

class App extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        channel_token: false,
        create_pool: false, 
        edit_pool: false,
        view_pool: false,
        commands: false,
        timed_commands: false,
        leaderboard: false,
        built_in_features: false,
        homepage: false,
        pool_id: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        faqs: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        alert: false,
        alert_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        privacy: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false
      }
      this.display_channel_token = this.display_channel_token.bind(this);
      this.display_leaderboard = this.display_leaderboard.bind(this);
      this.display_view_pools = this.display_view_pools.bind(this);
      this.display_edit_pool = this.display_edit_pool.bind(this);
      this.display_create_pool = this.display_create_pool.bind(this);
      this.display_features = this.display_features.bind(this);
      this.display_features_settings = this.display_features_settings.bind(this);
      this.display_commands = this.display_commands.bind(this)
      this.display_edit_command = this.display_edit_command.bind(this);
      this.display_add_command = this.display_add_command.bind(this);
      this.display_add_timed_commands = this.display_add_timed_commands.bind(this);
      this.display_edit_timed_commands = this.display_edit_timed_commands.bind(this);
      this.display_timed_commands = this.display_timed_commands.bind(this);
      this.withdraw_from_pool = this.withdraw_from_pool.bind(this);
      this.display_assets = this.display_assets.bind(this);
      this.display_settings = this.display_settings.bind(this);
      this.display_support = this.display_support.bind(this);
      this.display_transactions = this.display_transactions.bind(this);
      this.display_banned = this.display_banned.bind(this);
      this.display_add_banned = this.display_add_banned.bind(this);
      this.logged_in = this.logged_in.bind(this);
      this.update_alert = this.update_alert.bind(this);
      this.display_poll = this.display_poll.bind(this);
      this.display_privacy = this.display_privacy.bind(this);
      this.display_terms = this.display_terms.bind(this);
      this.display_homepage = this.display_homepage.bind(this);
      this.display_tip = this.display_tip.bind(this);
    }

    componentDidMount () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        document.title = 'Scurvy Bot - Twitch-Wax Interface';
        if(urlParams.has('channel_id')) {
          this.setState({
            channel_pools: true,
            pool_channel_id: urlParams.get('channel_id'),
            channel_token: false, 
            create_pool: false, 
            edit_pool: false, 
            view_pool: false, 
            commands: false, 
            timed_commands: false, 
            leaderboard: false, 
            built_in_features: false, 
            homepage: false,
            dig: false,
            chests: false,
            edit_commands: false,
            command: {},
            add_command: false,
            edit_timed_commands: false,
            add_timed_commands: false,
            timed_commands_object: {},
            withdraw: false,
            withdraw_object: {},
            assets: false,
            settings: false,
            support: false,
            transactions: false,
            banned: false,
            add_banned: false,
            edit_pool_object: {},
            shoutout: false,
            poll: false,
            faqs: false,
            policies: false,
            privacy: false,
            cookies: false,
            terms: false,
            channel_commands: false,
            commands_channel_id: '',
            tip: false
          })
        } else if (urlParams.has('commands_id')) {
          this.setState({
            channel_pools: false,
            pool_channel_id: '',
            channel_token: false, 
            create_pool: false, 
            edit_pool: false, 
            view_pool: false, 
            commands: false, 
            timed_commands: false, 
            leaderboard: false, 
            built_in_features: false, 
            homepage: false,
            dig: false,
            chests: false,
            edit_commands: false,
            command: {},
            add_command: false,
            edit_timed_commands: false,
            add_timed_commands: false,
            timed_commands_object: {},
            withdraw: false,
            withdraw_object: {},
            assets: false,
            settings: false,
            support: false,
            transactions: false,
            banned: false,
            add_banned: false,
            edit_pool_object: {},
            shoutout: false,
            poll: false,
            faqs: false,
            policies: false,
            privacy: false,
            cookies: false,
            terms: false,
            channel_commands: true,
            commands_channel_id: urlParams.get('commands_id'),
            tip: false
          })
        } else {this.setState({homepage: true})};
    }

    display_channel_token () {
      this.setState({
        channel_token: true, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false
      })
    }

    display_leaderboard () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: true, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_view_pools () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: true, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_edit_pool (data) {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: true, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false, 
        pool_id: data.pool_id,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: data,
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_create_pool (data) { // need to change to view pools once one is added.
      this.setState({
        channel_token: false, 
        create_pool: true, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_features (data) {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: true, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_features_settings (data) {
      let dig = false;
      let chests = false;
      let shoutout = false;
      if (data === 'chests') {
        chests = true;
      } else if (data === 'dig') {
        dig = true;
      } else {
        shoutout = true;
      }

      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: dig,
        chests: chests,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: shoutout,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_commands () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: true, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_edit_command (data) {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: true,
        command: data,
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_add_command () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: true,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_add_timed_commands () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: true,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_edit_timed_commands (data) {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: true,
        add_timed_commands: false,
        timed_commands_object: data,
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_timed_commands () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: true, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    withdraw_from_pool (data) {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: true,
        withdraw_object: data,
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_assets () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: true,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_settings () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: true,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_support () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: true,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_transactions () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: true,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_banned () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: true,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_add_banned () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: true,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    update_alert (alert) {
      this.setState({alert: true, alert_object: alert})
      setTimeout(() => {
        this.setState({alert: false, alert_object: {bool: false, type: '', message: ''}})
      }, 5000)
    }

    display_poll () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: true,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_privacy () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: true,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    
    display_terms () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: true,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }

    display_tip () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: false,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: true})
    }

    display_homepage () {
      this.setState({
        channel_token: false, 
        create_pool: false, 
        edit_pool: false, 
        view_pool: false, 
        commands: false, 
        timed_commands: false, 
        leaderboard: false, 
        built_in_features: false, 
        homepage: true,
        dig: false,
        chests: false,
        edit_commands: false,
        command: {},
        add_command: false,
        edit_timed_commands: false,
        add_timed_commands: false,
        timed_commands_object: {},
        withdraw: false,
        withdraw_object: {},
        assets: false,
        settings: false,
        support: false,
        transactions: false,
        banned: false,
        add_banned: false,
        edit_pool_object: {},
        shoutout: false,
        poll: false,
        channel_pools: false,
        pool_channel_id: '',
        faqs: false,
        policies: false,
        privacy: false,
        cookies: false,
        terms: false,
        channel_commands: false,
        commands_channel_id: '',
        tip: false})
    }


    logged_in () {
      let state_object = this.state;
      let valid_page = false;
      for (let key in state_object) {
        if (key === 'homepage') {
          state_object[key] = false;
        } else if (state_object[key] === true && key !== 'homepage') {
          valid_page = key;
        }
      }
      if (!valid_page && !this.state.channel_pools) {
        state_object['view_pool'] = true;
      }
      this.setState(state_object)
    }

    render() {
      let pages = '';
      if (this.state.channel_pools) {
        pages = <ChannelPools channel_id={this.state.pool_channel_id} pass_alert={this.update_alert}/>
      } else if (this.state.channel_commands) {
        pages = <ChannelCommands channel_id={this.state.commands_channel_id}/>
      } else {
        pages = <div className="home">
          <Sidebar 
            pass_channel_token={this.display_channel_token} 
            pass_leaderboard={this.display_leaderboard} 
            pass_view_pools={this.display_view_pools} 
            pass_create_pool={this.display_create_pool}
            pass_features={this.display_features}
            pass_commands={this.display_commands}
            pass_timed_commands={this.display_timed_commands}
            pass_settings={this.display_settings}
            pass_support={this.display_support}
            pass_transactions={this.display_transactions}
            pass_banned={this.display_banned}
            pass_tip={this.display_tip}
          />
          {this.state.homepage === true && <Homepage pass_credentials={this.logged_in} pass_logged_out={this.display_homepage} pass_alert={this.update_alert}/>}
          {this.state.channel_token === true && <ChannelToken pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.leaderboard === true && <Leaderboard pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.view_pool === true && <ViewPools pass_edit_pools={this.display_edit_pool} pass_withdraw_from_pool={this.withdraw_from_pool} pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.edit_pool === true && <EditPool pool_object={this.state.edit_pool_object} pass_credentials={this.logged_in} pass_pools={this.display_view_pools} pass_alert={this.update_alert}/>}
          {this.state.create_pool === true && <CreatePool pass_credentials={this.logged_in} pass_pools={this.display_view_pools} pass_alert={this.update_alert}/>}
          {this.state.built_in_features === true && <Features display_poll={this.display_poll} display_settings={this.display_features_settings} view_assets={this.display_assets} pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.chests === true && <Chests pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.dig === true && <Dig pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.commands === true && <Commands display_edit_command={this.display_edit_command} display_add_command={this.display_add_command} pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.edit_commands === true && <EditCommand command={this.state.command} pass_credentials={this.logged_in} pass_commands={this.display_commands} pass_alert={this.update_alert}/>}
          {this.state.add_command === true && <AddCommand pass_credentials={this.logged_in} pass_commands={this.display_commands} pass_alert={this.update_alert}/>}
          {this.state.timed_commands === true && <TimedCommands display_edit_timed_command={this.display_edit_timed_commands} display_add_timed_command={this.display_add_timed_commands} pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.edit_timed_commands === true && <EditTimedCommands command={this.state.timed_commands_object} pass_credentials={this.logged_in} pass_timed_commands={this.display_timed_commands} pass_alert={this.update_alert}/>}
          {this.state.add_timed_commands === true && <AddTimedCommand pass_credentials={this.logged_in} pass_timed_commands={this.display_timed_commands} pass_alert={this.update_alert}/>}
          {this.state.withdraw === true && <Withdraw pool={this.state.withdraw_object} pass_credentials={this.logged_in} pass_transactions={this.display_transactions} pass_alert={this.update_alert}/>}
          {this.state.assets === true && <Assets pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.settings === true && <Settings pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.support === true && <Support pass_credentials={this.logged_in} pass_alert={this.update_alert} pass_terms={this.display_terms} pass_privacy={this.display_privacy}/>}
          {this.state.transactions === true && <Transactions pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.banned === true && <Banned display_add_banned={this.display_add_banned} pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.add_banned === true && <AddBanned pass_credentials={this.logged_in} pass_banned={this.display_banned} pass_alert={this.update_alert}/>}
          {this.state.alert === true && <Alert alert={this.state.alert_object} pass_alert={this.update_alert}/>}
          {this.state.shoutout === true && <Shoutout pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.poll === true && <Poll pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
          {this.state.privacy === true && <Privacy />}
          {this.state.terms === true && <Terms />}
          {this.state.tip === true && <Tip pass_credentials={this.logged_in} pass_alert={this.update_alert}/>}
        </div>
      };
      
      return (
        pages
      );
    }
}

export default App;


