import axios from 'axios';
import react from 'react';
import './assets.css';
import Login from './login'
import Refresh from './refresh';

class Assets extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        assets: [],
        current_page: 1,
        deposit_memo: null,
        wallet: '',
        page_assets: [],
        checked: [],
      }
      this.withdraw_nft = this.withdraw_nft.bind(this);
      this.next_page = this.next_page.bind(this);
      this.previous_page = this.previous_page.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_asset_data = this.load_asset_data.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.load_wallet = this.load_wallet.bind(this);
      this.search_assets = this.search_assets.bind(this);
      this.add_to_checked = this.add_to_checked.bind(this);
      this.withdraw_multiple = this.withdraw_multiple.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_asset_data();
        this.load_wallet();
    }

    search_assets (e) {
        // find assets
        let found_assets = [];
        this.state.assets.map((x) => {
            if (x.asset_name.toLowerCase().includes(e.target.value) || x.asset_collection.toLowerCase().includes(e.target.value) || x.asset_collection.toLowerCase() === e.target.value || x.asset_name.toLowerCase() === e.target.value) {
                found_assets.push(x);
            }
        })
        this.setState({page_assets: found_assets});
    }

    refresh_page () {
        this.load_asset_data();
        this.load_wallet()
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    load_asset_data () {
        axios.get('https://scurvybot.com/api/dig_assets')
        .then((res) => {
            this.setState({assets: res.data, page_assets: res.data})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    } 

    load_wallet () {
        axios.get('https://scurvybot.com/api/get_user_wallet')
        .then((res) => {
            this.setState({wallet: res.data})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
    }

    next_page () {
        let current_page = this.state.current_page;
        if (current_page === Math.ceil(this.state.page_assets.length / 4) || this.state.page_assets.length === 0) {
            return;
        } else {
            current_page++;
            this.setState({current_page: current_page})
        }
    }

    previous_page () {
        let current_page = this.state.current_page;
        if (current_page === 1) {
            return;
        } else {
            current_page--;
            this.setState({current_page: current_page})
        }
    }

    withdraw_nft (e) {
        e.preventDefault();
        let wallet = this.state.wallet;
        if (this.state.assets.length === 0) {
            return;
        } else {
            function verify () {
                return new Promise((resolve, reject) => {
                  // eslint-disable-next-line no-restricted-globals
                  let confirmed = confirm('Are you sure you want to withdraw ' + e.target.name + '? \nNFT will be deposited to your assigned wallet: ' + wallet);
                  if (confirmed) {
                    resolve(true);
                  } else {
                    reject();
                  }
                })
              }
              verify()
              .then(() => {
                axios.post('https://scurvybot.com/api/withdraw_asset', {
                    asset_id: e.target.value
                })
                .then((res) => {
                    this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                        this.pass_alert();
                        this.load_asset_data();
                    })
                })
                .catch((err) => {
                    if (err.response.data.code === 301) {
                        window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
                    } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                        this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                            this.pass_alert()
                        })
                    }
                });
              })
              .catch(() => {
                return;
            })
        }
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    add_to_checked (e) {
        let checked_assets = this.state.checked;
        if (e.target.checked) checked_assets.push(e.target.name);
        else {
            let asset_index = checked_assets.indexOf(e.target.name);
            if (asset_index === -1) return;
            else checked_assets.splice(asset_index, 1);
        }
        this.setState({checked: checked_assets});
    }

    withdraw_multiple (e) {
        e.preventDefault();
        let assets = this.state.checked;
        let wallet = this.state.wallet;
        if (assets.length < 1) return;
        else {
            function verify () {
                return new Promise((resolve, reject) => {
                  // eslint-disable-next-line no-restricted-globals
                  let confirmed = confirm('Are you sure you want to withdraw the selected NFTs? NFTs will be deposited to your assigned wallet: ' + wallet);
                  if (confirmed) {
                    resolve(true);
                  } else {
                    reject();
                  }
                })
              }
              verify()
              .then(() => {
                // Do something: refresh page (refresh command array from backend.); // send nft with asset_id e.target.value to assigned user wallet.
                axios.post('https://scurvybot.com/api/withdraw_multiple_assets', {
                    assets: assets
                })
                .then((res) => {
                    this.setState({alert: {bool: true, type: 'success', message: res.data.message, checked: []}}, () => {
                        this.pass_alert();
                        this.load_asset_data();
                    })
                })
                .catch((err) => {
                    if (err.response.data.code === 301) {
                        window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
                    } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                        this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                            this.pass_alert()
                        })
                    }
                });
              })
              .catch(() => {
                return;
            })
        }
    }

    render () {
        let assets = this.state.page_assets.length >= 1 ? this.state.page_assets : [{asset_name: 'N/A', asset_id: 'N/A', asset_collection: 'N/A', asset_link: 'N/A'}];
        let current_page = assets.slice((this.state.current_page-1) * 4, (this.state.current_page * 4))
        let backwards = assets.length > 4 ? <button className="traversal-buttons previous assets" onClick={this.previous_page}>{'<< Previous Page'}</button> : null;
        let forwards = assets.length > 4 ? <button className="traversal-buttons next assets" onClick={this.next_page}>{'Next Page >>'}</button> : null;
        let multi_withdraw = this.state.checked.length > 0 ? <button className="withdraw withdraw_multi" onClick={this.withdraw_multiple}>Withdraw Selected</button> : null;
        let max_page = Math.ceil(this.state.assets.length / 4)
        return (
            <div className="assets-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h6 className="pageCount">
                    page {this.state.current_page} of {max_page}
                </h6>
                <div className="assets-list">
                    <h3 className="assets-title main-title">Dig Assets</h3>
                    <input className="search-bar" type="text" placeholder="search" onChange={this.search_assets}/>
                    <table className="assets-table">
                        <tr className="assets-rows">
                            <th className="assets-headings">Asset Name</th>
                            <th className="assets-headings">Asset Collection</th>
                            <th className="assets-headings"></th>
                            <th className="assets-headings">Actions</th>
                            <th className="assets-headings"></th>
                        </tr>
                        {current_page.map((x, index) => {
                            let checked = this.state.checked.includes(x.asset_id) ? true : false; 
                            let link = x.asset_link === 'N/A' ? false : true;
                            let resource = (x.nft_type === 'img') || (x.nft_type === 'gif') ? <img className="nft-thumnail" src={x.asset_link} alt="Dig NFT Asset"/> : <video className="nft-thumnail" src={x.asset_link} alt="Dig NFT Asset" autoplay muted></video>;
                            return (
                                <tr className="assets-rows" key={index}>
                                    <td className="assets-datacells">{x.asset_name}</td>
                                    <td className="assets-datacells">{x.asset_collection}</td>
                                    <td className="assets-datacells">{link ? resource : 'N/A'}</td>
                                    <td className="assets-datacells">
                                        <button className="withdraw" value={x.asset_id} name={x.asset_name} onClick={this.withdraw_nft}>Withdraw</button>
                                    </td>
                                    <td className="assets-datacells"><input type="checkbox" defaultChecked={false} checked={checked} onChange={this.add_to_checked} name={x.asset_id}/></td>
                                </tr>
                            )
                        })}
                    </table>
                    {multi_withdraw}
                    {backwards}
                    {forwards}
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Assets;