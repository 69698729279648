import axios from 'axios';
import react from 'react';
import './add_new_commands.css';
import Login from './login'

class AddBanned extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        user: '',
        alert: {}
      }
      this.set_user = this.set_user.bind(this);
      this.save_ban = this.save_ban.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_banned = this.pass_banned.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
    }

    set_user (e) {
        this.setState({user: e.target.value});
    }

    save_ban (e) {
        let user = this.state.user;
        e.preventDefault()
        function verify () {
            return new Promise((resolve, reject) => {
              // eslint-disable-next-line no-restricted-globals
              let confirmed = confirm('Are you sure you want to ban: ' + user)
              if (confirmed) {
                resolve(true);
              } else {
                reject();
              }
            })
          }
          verify()
          .then(() => {
            axios.post('https://scurvybot.com/api/add_banned', {
              user: user,
            })
            .then((res) => {
              this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.pass_alert();
                this.pass_banned();
              })
            })
            .catch((err) => {
              alert(err)
              if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
              } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                  this.pass_alert()
                })
              }
            });
          })
          .catch(() => {
            return;
          })
    }
    

    pass_alert () {
      this.props.pass_alert(this.state.alert)
    }

    pass_banned () {
      this.props.pass_banned()
    }

    pass_logged_in () {
      this.props.pass_credentials();
    }

    render () {
        return (
            <div className="add-command-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="add-command-title main-title">Ban User</h3>
                <div className="add-command-form">
                    <form id="add-command-form" onSubmit={this.save_ban}>
                        <label for="alias" className="add-command-label">User: </label>
                        <input id="alias" className="add-command-input" type="text" name="alias" placeholder='twitch username or wax wallet' onChange={this.set_user} required/>
                        <br/>
                        <p>Note: <br/><br/> 
                            <span>This will not ban a user from entering your channel.<br/>
                                Users will be banned from redeeming pool tokens and participating in other enabled scurvy features.
                            </span>
                        </p>
                        <button type="submit" className="add-command-form-button">Ban User</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddBanned;