import react from 'react';
import './loyalty-submenu.css';

class SubmenuLoyalty extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        active_submenu: this.props.active_submenu
      }
      this.display_channel_token_page = this.display_channel_token_page.bind(this);
      this.display_leaderboard_page = this.display_leaderboard_page.bind(this);
      this.display_banned = this.display_banned.bind(this);
      this.reset_menu = this.reset_menu.bind(this);
      this.display_tip_page = this.display_tip_page.bind(this);
    }

    display_channel_token_page () {
        this.props.pass_channel_token(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_leaderboard_page () {
        this.props.pass_leaderboard(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_banned () {
        this.props.pass_banned()
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_tip_page () {
        this.props.pass_tip();
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    reset_menu () {
        this.props.reset_menu();
    } 

    render () {
        return (
            <div className="sub-menus">
                <div className="token sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_channel_token_page}>Channel Token</h4>
                </div>
                <div className="tip sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_tip_page}>Tip</h4>
                </div>
                <div className="leaderboard sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_leaderboard_page}>Leaderboard</h4>
                </div>
                <div className="leaderboard sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_banned}>Banned</h4>
                </div>
            </div>
        )
    }
}

export default SubmenuLoyalty;