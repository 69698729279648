import react from 'react';
import './settings.css';
import Login from './login'

class Support extends react.Component {
    constructor (props) {
      super(props);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.display_privacy = this.display_privacy.bind(this);
      this.display_terms = this.display_terms.bind(this);
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }
      
    display_terms () {
        this.props.pass_terms();
    }

    display_privacy () {
        this.props.pass_privacy();
    }

    render () {
        return (
            <div className="settings-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="settings-title main-title">Support</h3>
                <div className="settings-form">
                    <p>For issues relating to account, transactions, etc. please email: <a href="mailto:customer-support@mail.scurvybot.com" className="support-links"> customer-support@mail.scurvybot.com</a></p>
                    <p>Join the <a href="https://discord.gg/s7xnTTEk95" target="_blank" rel="noreferrer" className="support-links">discord</a> to get in contact with staff, or friendly members of the community for general interaction, support and queries.</p>
                    <p>Want to see the bot in action? Check out the brains behind the idea of the project on <a href="https://twitch.tv/captaindegen_" target="_blank" rel="noreferrer" className="support-links">twitch</a> and experience the first version of our bot live.</p>
                </div>
                <div id="policyButtonDiv">
                    <button onClick={this.display_privacy} className="policyButton privacyButton">Privacy Policy</button>
                    <button onClick={this.display_terms} className="policyButton privacyButton">Terms Of Use</button>
                </div>
            </div>
        )
    }
}

export default Support;