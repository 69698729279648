import axios from 'axios';
import react from 'react';
import './dig.css';
import Login from './login'
import Refresh from './refresh';

class Dig extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        percentage: 25,
        cost: 10,
        cooldown: 60,
        enabled: 'on',
        alert: {},
        prem_enabled: 'off',
        template_id: 0
      }
      this.set_cost = this.set_cost.bind(this);
      this.set_percentage = this.set_percentage.bind(this);
      this.set_cooldown = this.set_cooldown.bind(this);
      this.save_dig_settings = this.save_dig_settings.bind(this);
      this.set_enabled = this.set_enabled.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_dig_settings = this.load_dig_settings.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
      this.set_prem_enabled = this.set_prem_enabled.bind(this);
      this.set_template_id = this.set_template_id.bind(this);
    }

    componentDidMount () {
        this.load_dig_settings();
    }

    load_dig_settings () {
        axios.get('https://scurvybot.com/api/dig_settings')
        .then((res) => {
            let enabled = res.data.enabled === 1 ? 'on' : 'off';
            let prem_enabled = res.data.prem_only === 1 ? 'on' : 'off';
            this.setState({percentage: res.data.percentage, cost: res.data.cost, cooldown: res.data.cooldown, enabled: enabled, template_id: res.data.template_id, prem_enabled: prem_enabled})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
              this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                this.pass_alert()
              })
            }
          });
    }

    set_cost (e) {
        this.setState({cost: e.target.value})
    }

    set_percentage (e) {
        this.setState({percentage: e.target.value})
    }

    set_cooldown (e) {
        this.setState({cooldown: e.target.value})
    }

    set_enabled (e) {
        this.setState({enabled: e.target.value})
    }

    set_prem_enabled (e) {
        this.setState({prem_enabled: e.target.value});
    }

    set_template_id (e) {
        this.setState({template_id: e.target.value});
    }

    save_dig_settings (e) {
        e.preventDefault();
        let template_id = this.state.template_id === 'Leave empty for sub only' ? 0 : this.state.template_id;
        template_id = template_id === '' ? 0 : this.state.template_id;
        axios.post('https://scurvybot.com/api/save_dig_settings', {
            cost: this.state.cost,
            percentage: this.state.percentage,
            cooldown: this.state.cooldown,
            enabled: this.state.enabled,
            prem: this.state.prem_enabled,
            template_id: template_id
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.load_dig_settings();
                this.pass_alert();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    refresh_page () {
        this.load_dig_settings()
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let dig_cost = this.state.cost ? this.state.cost : 1;
        let percentage = this.state.percentage ? this.state.percentage : 25;
        let cooldown = this.state.cooldown ? this.state.cooldown : 60;
        let template_id_value = this.state.template_id === 0 ? 'Leave empty for sub only' : this.state.template_id;
        let enabled_radio_true = this.state.enabled === 'on' ? true : false;
        let enabled_radio_false = this.state.enabled === 'on' ? false : true;
        let prem_enabled_radio_true = this.state.prem_enabled === 'on' ? true : false;
        let prem_enabled_radio_false = this.state.prem_enabled === 'on' ? false : true;
        let template_id = this.state.prem_enabled === 'on' ? <div className="dig-template-id-div"><label for="template_id" className="dig-settings-label">Dig Pass Template ID: </label><input type="number" className="dig-settings-input" id="template_id" name="template_id" placeholder="Leave empty for sub only" value={template_id_value} onChange={this.set_template_id}/></div> : null
        
        return (
            <div className="dig-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="dig-title main-title">Dig Settings</h3>
                <div className="dig-form">
                    <form id="dig-form" onSubmit={this.save_dig_settings}>
                        <div className="dig-form-item">
                            <label for="dig-cost" className="dig-settings-label">Cost per dig:</label>
                            <input id="dig-cost" className="dig-settings-input" type="number" name="dig-cost" min="1" placeholder={dig_cost} value={this.state.cost} onChange={this.set_cost} required/>
                        </div>
                        <br/>
                        <div className="dig-form-item">
                            <label for="percentage" className="dig-settings-label">Success Percentage: </label>
                            <input type="number" className="dig-settings-input" id="percentage" name="percentage" min="1" max="100" placeholder={percentage} value={this.state.percentage} onChange={this.set_percentage} required/>
                        </div>
                        <br/>
                        <div className="dig-form-item">
                            <label for="cooldown" className="dig-settings-label">Cooldown Per User (minutes): </label>
                            <input type="number" className="dig-settings-input" id="cooldown" name="cooldown" min="0" max="1000000" placeholder={cooldown} value={this.state.cooldown} onChange={this.set_cooldown} required/>
                        </div>
                        <br/>
                        <div className="dig-form-item">
                            <label for="prem" className="dig-settings-label">Sub/Pass Only: </label>
                            <div name="prem">
                                <input type="radio" className="dig-settings-input" id="form-prem" name="prem" onChange={this.set_prem_enabled} value='on' checked={prem_enabled_radio_true}></input>
                                <label className="radio-labels">Enable</label>
                                <input type="radio" className="dig-settings-input" id="form-prem" name="prem" onChange={this.set_prem_enabled} value='off' checked={prem_enabled_radio_false}></input>
                                <label className="radio-labels">Disable</label>
                            </div>
                            {template_id}
                        </div>
                        <br/>
                        <div className="dig-form-item">
                            <label for="enable" className="dig-settings-label" required>Enable</label>
                            <div name="enable">
                                <input type="radio" className="dig-settings-input" id="form-enable" name="enable" onChange={this.set_enabled} value='on' checked={enabled_radio_true}></input>
                                <label className="radio-labels">Enable</label>
                                <input type="radio" className="dig-settings-input" id="form-disable" name="enable" onChange={this.set_enabled} value='off' checked={enabled_radio_false}></input>
                                <label className="radio-labels">Disable</label>
                            </div>
                        </div>
                        <button type="submit" className="token-form-button dig-save-button">Save Dig Settings</button>
                    </form>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Dig;