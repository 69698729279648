import axios from 'axios';
import react from 'react';
import './banned.css';
import Login from './login'
import Refresh from './refresh';

class Banned extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        banned: [],
        current_page: 1,
        alert: {}
      }
      this.next_page = this.next_page.bind(this);
      this.previous_page = this.previous_page.bind(this);
      this.add_banned = this.add_banned.bind(this);
      this.unban = this.unban.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_banned = this.load_banned.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
      this.load_banned();
    }

    load_banned () {
      axios.get('https://scurvybot.com/api/banned')
      .then((res) => {
        this.setState({banned: res.data})
      })
      .catch((err) => {
        if (err.response.data.code === 301) {
          window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
        } else if (err.response.data.code === 500 || err.response.data.code === 400) {
          this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
            this.pass_alert()
          })
        }
      });
    }
    
    next_page () {
        let current_page = this.state.current_page;
        if (current_page === Math.ceil(this.state.banned.length / 6)) {
            return;
        } else {
            current_page++;
            this.setState({current_page: current_page})
        }
    }

    previous_page () {
        let current_page = this.state.current_page;
        if (current_page === 1) {
            return;
        } else {
            current_page--;
            this.setState({current_page: current_page})
        }
    }

    add_banned (e) {
        this.props.display_add_banned()
    }

    unban (e) {
        e.preventDefault();
        function verify () {
            return new Promise((resolve, reject) => {
              // eslint-disable-next-line no-restricted-globals
              let confirmed = confirm('Are you sure you want to unban: ' + e.target.value)
              if (confirmed) {
                resolve(true);
              } else {
                reject();
              }
            })
          }
          verify()
          .then(() => {
            axios.post('https://scurvybot.com/api/remove_ban', {
              user: e.target.value
            })
            .then((res) => {
              this.setState({alert: {bool: true, type: 'success', message: res.data.message}, current_page: 1}, () => {
                this.load_banned()
                this.pass_alert();
            })
            })
            .catch((err) => {
              if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
              } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                  this.pass_alert()
                })
              }
            });
          })
          .catch(() => {
            return;
          })
    }

    pass_alert () {
      this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
      this.props.pass_credentials();
    }

    refresh_page () {
      this.load_banned();
      this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
        this.pass_alert()
      })
    }

    render () {
        let banned = this.state.banned.length === 0 ? [{user: 'N/A'}] : this.state.banned;
        let current_page = banned.slice((this.state.current_page-1) * 6, (this.state.current_page * 6))
        let backwards = this.state.banned.length > 6 ? <button className="traversal-buttons previous" onClick={this.previous_page}>{'<< Previous Page'}</button> : null;
        let forwards = this.state.banned.length > 6 ? <button className="traversal-buttons next" onClick={this.next_page}>{'Next Page >>'}</button> : null;

        return (
            <div className="commands-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="commands-list">
                    <h3 className="banned-title main-title">Banned</h3>
                    <button id="add_command_button" onClick={this.add_banned}>+ Add banned user</button>
                    <table className="banned-table">
                        <tr className="commands-rows">
                            <th className="commands-headings">Username/wallet</th>
                            <th className="commands-headings">Actions</th>
                        </tr>
                        {current_page.map((x, index) => {
                            let actions = this.state.banned.length < 1 ? <td className="commands-datacells commands-actions"></td> : <td className="commands-datacells commands-actions"><button className="unban-button" value={x.user} onClick={this.unban}>Unban</button></td>
                            return (
                                <tr className="commands-rows" key={index}>
                                    <td className="commands-datacells">{x.user}</td>
                                    {actions}
                                </tr>
                            )
                        })}
                    </table>
                    <div className="commands-traversal-button-container">
                      {backwards}
                      {forwards}
                    </div>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Banned;