import axios from 'axios';
import react from 'react';
import './features.css';
import Login from './login'
import Refresh from './refresh';

class Features extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        features: [
            {feature: 'Create A Poll', description: `Create a poll using scurvy interface.`, status: {enabled: 'true'}},
            {feature: 'redemptions', description: `Allows viewers to redeem from your pools.`, status: {enabled: 'false'}},
            {feature: 'clips', description: 'Create clips using !clip. Streamer and Mod only.', status: {enabled: 'false'}},
            {feature: 'chests', description: 'Drop a token chest per 10 viewers', status: {enabled: 'true'}}, 
            {feature: 'shoutout', description: `Custom shoutout message. Streamer & Mod only.`, status: {enabled: 'true'}},
            {feature: 'dig', description: `NFT pools for a chance for users to claim NFTs for tokens`, status: {enabled: 'true'}}

        ],
        nft_memo: null,
        alert: {}
      }
      this.change_feature_status = this.change_feature_status.bind(this);
      this.display_settings = this.display_settings.bind(this);
      this.view_pool_assets = this.view_pool_assets.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.display_poll = this.display_poll.bind(this);
      this.load_initial_data = this.load_initial_data.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
       this.load_initial_data();
    }

    load_initial_data () {
        axios.get('https://scurvybot.com/api/feature_status')
        .then((res) => {
            let chests_enabled = res.data.chest.enabled === 1 ? 'true' : 'false';
            let dig_enabled = res.data.dig.enabled === 1 ? 'true' : 'false';
            let shoutouts_enabled = res.data.shoutouts.enabled ===  1 ? 'true' : 'false';
            let redeems_enabled = res.data.redemptions.enabled === 1 ? 'true' : 'false';
            let clips_enabled = res.data.clips.enabled === 1 ? 'true' : 'false';
            let features = this.state.features;
            features[1].status.enabled = redeems_enabled;
            features[2].status.enabled = clips_enabled;
            features[3].status.enabled = chests_enabled;
            features[4].status.enabled = shoutouts_enabled;
            features[5].status.enabled = dig_enabled;
            this.setState({features: features});
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
        axios.get('https://scurvybot.com/api/nft_deposit_memo')
        .then((res) => this.setState({nft_memo: res.data}))
        .catch((err) => {
            this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                this.pass_alert()
            })
        });
    }

    change_feature_status (e) {
        let features = this.state.features;
        let feature_name = null;
        if (e.target.name === '1') {
            feature_name = 'redemptions'
        } else if (e.target.name === '2') {
            feature_name = 'clips';
        } else if (e.target.name === '3') {
            feature_name = 'chest';  
        } else if (e.target.name === '4') {
            feature_name = 'shoutout';
        } else if (e.target.name === '5') {
            feature_name = 'dig'
        }
        let status = e.target.value === 'true' ? 'true' : 'false';
        features[e.target.name].status.enabled = status;
        axios.post(`https://scurvybot.com/api/update_${feature_name}_enabled`, {
            status: status
        })
        .then(() => this.setState({features: features}))
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert);
    }

    display_settings (e) {
        this.props.display_settings(this.state.features[e.target.value].feature);
    }

    display_poll () {
        this.props.display_poll();
    }

    view_pool_assets () {
        this.props.view_assets();
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    refresh_page () {
        this.load_initial_data()
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        return (
            <div className="features-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="features-list">
                    <h3 className="features-title main-title">Features</h3>
                    <table className="features-table">
                        <tr className="features-rows">
                            <th className="features-headings">Feature</th>
                            <th className="features-headings">Description</th>
                            <th className="features-headings">Actions</th>
                        </tr>
                        {this.state.features.map((x, index) => {
                            let actions = this.state.features[index].status.enabled === 'true' ? <button name={index} value={false} onClick={this.change_feature_status} className="disable-button">Disable</button> : <button name={index} value={true} onClick={this.change_feature_status} className="enable-button">Enable</button>;
                            let dig_deposit = this.state.features[index].feature === 'dig' ? <button name={index} className="deposit-button"><a href={`https://wax.atomichub.io/trading/nft-transfer?partner=scurvybot.gm&memo=${this.state.nft_memo}`} target="_blank" rel="noreferrer">Deposit</a></button> : null;
                            let dig_view_pool = this.state.features[index].feature === 'dig' ? <button name={index} onClick={this.view_pool_assets} className="asset-button">Assets</button> : null;
                            let settings = this.state.features[index].feature !== 'Create A Poll' ? <td className="features-datacells features-actions">{actions}{dig_deposit}{dig_view_pool}<button value={index} onClick={this.display_settings} className="settings-button">Settings</button></td> : <td className="features-datacells features-actions"><button value={index} onClick={this.display_poll} className="poll-button">Create</button></td>
                            settings = this.state.features[index].feature === 'redemptions' ? <td className="features-datacells features-actions">{actions}</td> : settings;
                            settings = this.state.features[index].feature === 'clips' ? <td className="features-datacells features-actions">{actions}</td> : settings;
                            return (
                                <tr key={index} id="test">
                                    <td className="features-datacells">{this.state.features[index].feature}</td>
                                    <td className="features-datacells features-description">{this.state.features[index].description}</td>
                                    {settings}
                                </tr>
                            )
                        })}
                    </table>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Features;