import react from 'react';

class Alert extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        alert: this.props.alert
      }
    }

    render () {
        let alert_title = this.state.alert.type === 'success' ? 'Success:' : 'Error:'
        
        if (this.state.alert.bool) {
            return (
                <div className="alert_box" id={this.state.alert.type}>
                    <h3>{alert_title}</h3>
                    <p>{this.state.alert.message}</p>
                </div>
            )
        } else {
            return;
        }
    }
}

export default Alert;