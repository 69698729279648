import axios from 'axios';
import react from 'react';
import './commands.css';

class ChannelCommands extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            channel_id: this.props.channel_id,
            commands: [],
            current_page: 1,
            channel_name: ''
        }
        this.next_page = this.next_page.bind(this);
        this.previous_page = this.previous_page.bind(this);
    };

    componentDidMount () {
        axios.get(`https://scurvybot.com/api/get_commands/?commands_id=${this.state.channel_id}`)
        .then((res) => this.setState({commands: res.data}))
        .catch((err) => console.error('Failed to load channel commands'));
        axios.get(`https://scurvybot.com/api/get_channel_name?channel_id=${this.state.channel_id}`)
        .then((res) => this.setState({channel_name: res.data.message}))
        .catch((err) => console.error('Failed to get channel name'));
    }
    
    next_page () {
        let current_page = this.state.current_page;
        if (current_page === Math.ceil(this.state.commands.length / 10)) {
            return;
        } else {
            current_page++;
            this.setState({current_page: current_page})
        }
    }

    previous_page () {
        let current_page = this.state.current_page;
        if (current_page === 1) {
            return;
        } else {
            current_page--;
            this.setState({current_page: current_page})
        }
    }

    render () {
        let commands = this.state.commands;
        let current_page = commands.slice((this.state.current_page-1) * 10, (this.state.current_page * 10))
        let backwards = this.state.commands.length > 10 ? <button className="traversal-buttons previous" onClick={this.previous_page}>{'<< Previous Page'}</button> : null;
        let forwards = this.state.commands.length > 10 ? <button className="traversal-buttons next" onClick={this.next_page}>{'Next Page >>'}</button> : null;

        return (
            <div>
                <div className="header">
                    <a href="https://scurvybot.com/"><img className="logo" alt="scurvy-logo" src="./logo.png"/></a>
                    <p className="title">Scurvy Bot</p>
                </div>
                <div className="commands-list">
                    <h3 className="commands-title main-title">{this.state.channel_name}'s Commands</h3>
                    <table className="commands-table">
                        <tr className="commands-rows">
                            <th className="commands-headings">Command</th>
                            <th className="commands-headings">Response</th>
                            <th className="commands-headings">Mod Only</th>
                        </tr>
                        {current_page.map((x, index) => {
                            let mod_only = x.mod_only === 1 ? 'Yes' : 'No';
                            return (
                                <tr className="commands-rows" key={index}>
                                    <td className="commands-datacells">{x.command}</td>
                                    <td className="commands-datacells">{x.action}</td>
                                    <td className="commands-datacells">{mod_only}</td>
                                </tr>
                            )
                        })}
                    </table>
                    <div className="commands-traversal-button-container">
                        {backwards}
                        {forwards}
                    </div>
                </div>
            </div>
        );
    };
};

export default ChannelCommands;