import react from 'react';
import './withdraw.css';
import Login from './login'
import axios from 'axios';

class Withdraw extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        pool_name: this.props.pool.pool_name,
        token: this.props.pool.token,
        pool_id: this.props.pool.pool_id,
        balance: this.props.pool.balance,
        amount: 0,
        alert: {}
      }
      this.set_withdraw_amount = this.set_withdraw_amount.bind(this);
      this.withdraw_from_pool = this.withdraw_from_pool.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_transactions = this.pass_transactions.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
    }

    set_withdraw_amount (e) {
        this.setState({amount: e.target.value});
    }

    withdraw_from_pool (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/token_withdrawal', {
            pool_id: this.state.pool_id,
            amount: this.state.amount,
            token: this.state.token,
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.pass_alert();
                this.pass_transactions();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_transactions () {
        this.props.pass_transactions();
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    render () {
        return (
            <div className="withdraw-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="withdraw-title main-title">Withdraw</h3>
                <div className="withdraw-form">
                    <form id="withdraw-form" onSubmit={this.withdraw_from_pool}>
                        <label for="pool_name" className="pool-label">Pool Name: </label>
                        <input id="pool_name" className="pool-input" type="text" name="pool_name" value={this.state.pool_name}/>
                        <br/>
                        <label for="redemption_rate" className="pool-label">Token: </label>
                        <input type="text" className="pool-input" id="redemption_rate" name="redemption_rate" min="1" max="100" value={this.state.token}/>
                        <br/>
                        <label for="amount" className="pool-label">Amount: </label>
                        <input type="number" className="pool-input" id="amount" name="amount" min="0" max={this.state.balance} placeholder="1" onChange={this.set_withdraw_amount} required/>
                        <button type="submit" className="pool-form-button">Withdraw</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Withdraw;