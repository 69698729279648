import react from 'react';
import './homepage.css';
import Login from './login';

class Homepage extends react.Component {
    constructor (props) {
      super(props);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_logged_out = this.pass_logged_out.bind(this);
    }

    pass_logged_out () {
        this.props.pass_logged_out();
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }
    
    render () {
        return (
            <div className="homepage">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in} pass_logged_out={this.pass_logged_out}/>
                </div>
                <section className="project-description-section">
                    <h2 className="project-title main-title">Scurvy Bot</h2>
                    <p className="project-description">
                    A new kind of loyalty program.
                    <br />
                    <br /> 
                    Streamers can create custom pools of Wax tokens on the website, and deposit into them.
                    Viewers can then earn free stream loyalty tokens by watching the stream, following, subscribing,
                    participating, and supporting their favourite integrated streamers! <br />
                    <br />
                    Viewers can then use their loyalty token to redeem real tokens from the streamer's pools.
                    <br />
                    <br /> The website provides a loyalty bot that tracks the viewer's progress and notifies them of their coin
                    balance and redemption options in chat via preset commands. <br />
                    <br />
                    The website uses the Twitch API and Wax API to integrate with the Twitch platform and provide a seamless
                    experience for both streamers and viewers.
                    <br />
                    <br />
                    <a className="description_login_link" href="https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters">Login with twitch</a> to get started!
                </p>
                </section>
            </div>
        );
    }
}

export default Homepage;