import react from 'react';
import './transactions.css';
import Login from './login'
import axios from 'axios';
import Refresh from './refresh';

class Transactions extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        transactions: [],
        current_page: 1,
        alert: {}
      }
      this.next_page = this.next_page.bind(this);
      this.previous_page = this.previous_page.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.load_transactions = this.load_transactions.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_transactions()
    }

    load_transactions () {
        axios.get('https://scurvybot.com/api/transactions')
        .then((res) => {
            res.data.map((x) => {x.date = x.date.substring(0, x.date.length-5)});
            this.setState({transactions: res.data});
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    next_page () {
        let current_page = this.state.current_page;
        if (current_page === Math.ceil(this.state.transactions.length / 8)) {
            return;
        } else {
            current_page++;
            this.setState({current_page: current_page})
        }
    }

    previous_page () {
        let current_page = this.state.current_page;
        if (current_page === 1) {
            return;
        } else {
            current_page--;
            this.setState({current_page: current_page})
        }
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }

    refresh_page () {
        this.load_transactions();
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let view_transactions = this.state.transactions.length >= 1 ? this.state.transactions : [{type: 'N/A', user: 'N/A', amount: 'N/A', token: 'N/A', transaction: 'N/A', date: 'N/A'}];
        let current_page = view_transactions.slice((this.state.current_page-1) * 8, (this.state.current_page * 8))
        let backwards = this.state.transactions.length > 8 ? <button className="traversal-buttons previous" onClick={this.previous_page}>{'<< Previous Page'}</button> : null;
        let forwards = this.state.transactions.length > 8 ? <button className="traversal-buttons next" onClick={this.next_page}>{'Next Page >>'}</button> : null;

        return (
            <div className="transactions-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="transactions-list">
                    <h3 className="transactions-title  main-title">Transactions</h3>
                    <table className="transactions-table">
                        <tr className="transactions-rows">
                            <th className="transactions-headings">Type</th>
                            <th className="transactions-headings">Token</th>
                            <th className="transactions-headings">Amount</th>
                            <th className="transactions-headings">User</th>
                            <th className="transactions-headings">Trans.</th>
                            <th className="transactions-headings hidden-heading">Date</th>
                        </tr>
                        {current_page.map((x, index) => {
                            let link = x.transaction === 'N/A' ? 'N/A' : <a href={`https://wax.bloks.io/transaction/${x.transaction}`} target="_blank" rel="noreferrer">View</a>
                            return (
                                <tr className="transactions-rows" key={index}>
                                    <td className="transactions-datacells">{x.type}</td>
                                    <td className="transactions-datacells">{x.token}</td>
                                    <td className="transactions-datacells">{x.amount}</td>
                                    <td className="transactions-datacells">{x.user}</td>
                                    <td className="transactions-datacells link">{link}</td>
                                    <td className="transactions-datacells hidden-cell">{x.date.replace('T', ' ')}</td>
                                </tr>
                            )
                        })}
                    </table>
                    <div style={{display: 'grid'}} className="traversal-button-container">
                        {backwards}
                        {forwards}
                    </div>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Transactions; 