import react from 'react';
import axios from 'axios';
import Login from './login';
import './tip.css';

class Tip extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            alert: {}
        }
        this.send_csv = this.send_csv.bind(this);
        this.pass_alert = this.pass_alert.bind(this);
        this.pass_logged_in = this.pass_logged_in.bind(this);
    };

    send_csv (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/tip_users', {
            column: e.target.name_column.value,
            csv: e.target.csv_input.value,
            amount: e.target.amount.value
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: 'Successfully tipped users'}}, () => {this.pass_alert()})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }
    
    render () {
        return (
            <div className="tip-component">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="form-div-tip">
                    <h3 className="transactions-title  main-title">Multi Tip</h3>
                    {//<h1 style={{color: "white"}}>INCOMPLETE. DO NOT USE YET</h1>
                    }
                    <form name="tip-form" onSubmit={this.send_csv}>
                        <div className="tip-form-item">
                            <label for="amount">Tip amount:</label>
                            <input type="number" name="amount" id="amount" min="1"></input>
                        </div>
                        <div className="tip-form-item">
                            <label for="name_column">Username column #: </label>
                            <input type="number" name="name_column" id="name_column" min="1"></input>
                        </div>
                        <div className="tip-form-item">
                            <label for="csv_input">CSV List:</label><br/>
                            <textarea name="csv_input" id="csv_input" cols="25" rows="10"></textarea>
                        </div>
                        <div className="tip-form-button">
                            <button type="submit" id="tipSendButton">Tip Users</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };
};

export default Tip