import react from 'react';
import './create_pool.css';
import Login from './login';
import axios from 'axios';
import Refresh from './refresh';

class CreatePool extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        token_name: null, 
        pool_name: null,
        redemption_rate: 25,
        contract: '',
        valid_tokens: [],
        alert: {}
      }
      this.set_pool_token = this.set_pool_token.bind(this);
      this.set_pool_name = this.set_pool_name.bind(this);
      this.set_redemption_rate = this.set_redemption_rate.bind(this);
      this.save_pool = this.save_pool.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_pools = this.pass_pools.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.load_initial_data = this.load_initial_data.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_initial_data();
    }

    load_initial_data () {
        axios.get('https://wax.alcor.exchange/api/v2/tokens')
        .then((res) => {
            res.data.push({symbol: 'LVAPE', contract: 'lasvegaskind'})
            res.data.push({symbol: 'BALLZ', contract: 'goofancor.gm'})
            res.data.sort((a, b) => a.symbol.localeCompare(b.symbol));
            this.setState({valid_tokens: res.data, token_name: res.data[0].symbol, contract: res.data[0].contract});
        })
        .catch((err) => {
            this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                this.pass_alert()
            })
        });
        axios.get('https://scurvybot.com/api/validate_login')
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
              } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                  this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    set_pool_token (e) {
        let token = e.target.value.split('-')[0];
        let contract = e.target.value.split('-')[1];
        this.setState({token_name: token, contract: contract});
    }

    set_pool_name (e) {
        this.setState({pool_name: e.target.value})
    }

    set_redemption_rate (e) {
        this.setState({redemption_rate: e.target.value});
    }

    save_pool (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/create_pool', {
            token: this.state.token_name,
            pool_name: this.state.pool_name,
            redemption_rate: this.state.redemption_rate,
            contract: this.state.contract
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.pass_alert();
                this.pass_pools();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters";
            else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_pools () {
        this.props.pass_pools();
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }

    refresh_page () {
        this.load_initial_data()
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let tokens = this.state.valid_tokens.length > 1 ? this.state.valid_tokens : [{symbol: 'Error fetching tokens - Try again later.'}]
        return (
            <div className="create-pool-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="create-pool-title main-title">Create a New Pool</h3>
                <div className="create-pool-form">
                    <form id="pool-form" onSubmit={this.save_pool}>
                        <label for="pool_name" className="pool-label">Pool Name: </label>
                        <input id="pool_name" className="pool-input" type="text" name="pool_name" placeholder="New Pool" onChange={this.set_pool_name} required/>
                        <br/>
                        <label for="token_name" className="pool-label">Token: </label>
                        <select onChange={this.set_pool_token}>
                            {tokens.map((x) => {
                                return (
                                    <option value={x.symbol + '-' + x.contract}>{x.symbol} ({x.contract})</option>
                                )
                            })}
                        </select>
                        <br/>
                        <label for="redemption_rate" className="pool-label" title="The percentage at which your stream token is converted to the pools token">Conversion Rate (%)</label>
                        <input type="number" className="pool-input" id="redemption_rate" name="redemption_rate" min="1" max="100000" placeholder="25" onChange={this.set_redemption_rate} required/>
                        <button type="submit" className="pool-form-button">Save Pool</button>
                    </form>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default CreatePool;