import axios from 'axios';
import react from 'react';
import './leaderboard.css';
import Login from './login';
import Refresh from './refresh';

class Leaderboard extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        leaderboard: [],
        alert: {},
        loading: true
      }
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
      this.load_leaderboard = this.load_leaderboard.bind(this);
    }

    componentDidMount () {
        this.load_leaderboard();
    }

    load_leaderboard () {
        axios.get('https://scurvybot.com/api/leaderboard')
        .then((res) => {
            this.setState({leaderboard: res.data, loading: false});
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({loading: false, alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }

    refresh_page () {
        this.load_leaderboard()
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let leaderboard = this.state.leaderboard.length >= 1 ? this.state.leaderboard : [{username: 'N/A', balance: 0}];
        let loading = !this.state.loading ? '' : 'loading ...';

        return (
            <div className="leaderboard-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="leaderboard-list">
                    <h3 className="leaderboard-title main-title">Leaderboard {loading}</h3>
                    <table className="leaderboard-table">
                        <tr className="leaderboard-rows">
                            <th className="leaderboard-headings">Username</th>
                            <th className="leaderboard-headings">Balance</th>
                            <th className="leaderboard-headings">Ranking</th>
                        </tr>
                        {leaderboard.map((x, index) => {
                            return (
                                <tr className="leaderboard-rows" key={index}>
                                    <td className="leaderboard-datacells">{x.username}</td>
                                    <td className="leaderboard-datacells">{x.balance}</td>
                                    <td className="leaderboard-datacells">{index + 1}</td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Leaderboard;