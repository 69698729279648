import axios from 'axios';
import react from 'react';
import './login.css';

class Login extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        is_logged_in: false
      }
      this.pass_credentials = this.pass_credentials.bind(this);
      this.pass_logged_out = this.pass_logged_out.bind(this);
    }

    componentDidMount () {
      axios.get("https://scurvybot.com/api/is_logged_in")
      .then((res) => {
        this.setState({is_logged_in: true})
        this.pass_credentials();
      })
      .catch((err) => {
        this.pass_logged_out();
        console.log('Logged Out');
      });
    }

    pass_logged_out () {
      this.props.pass_logged_out();
    }

    pass_credentials () {
      this.props.pass_logged_in();
    }

    render () {
      let account_button = this.state.is_logged_in ? <button className="twitch-button"><a className="logout twitch-link" href="https://scurvybot.com/api/logout">Logout</a></button> : <button className="twitch-button"><a className="login twitch-link" href="https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters">Login with twitch</a></button>
      return (
        account_button
      )
    }
}

export default Login;

