import axios from 'axios';
import react from 'react';
import './add_new_commands.css';
import Login from './login'

class AddCommand extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        alias: '',
        response: '',
        mod_only: 'off',
        alert: {}
      }
      this.set_alias = this.set_alias.bind(this);
      this.set_response = this.set_response.bind(this);
      this.save_command = this.save_command.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_commands = this.pass_commands.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.set_mod_only = this.set_mod_only.bind(this);
    }

    set_alias (e) {
        this.setState({alias: e.target.value});
    }

    set_response (e) {
        this.setState({response: e.target.value});
    }

    set_mod_only (e) {
        this.setState({mod_only: e.target.value});
    }

    save_command (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/add_new_command', {
            command: this.state.alias,
            action: this.state.response,
            mod_only: this.state.mod_only
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.pass_alert();
                this.pass_commands();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_commands () {
        this.props.pass_commands();
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    render () {
        let enabled_radio_true = this.state.mod_only === 'on' ? true : false;
        let enabled_radio_false = this.state.mod_only === 'on' ? false : true;

        return (
            <div className="add-command-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="add-command-title main-title">Add New Command</h3>
                <div className="add-command-form">
                    <form id="add-command-form" onSubmit={this.save_command}>
                        <label for="alias" className="add-command-label">New Alias: </label>
                        <input id="alias" className="add-command-input" type="text" name="alias" placeholder='!command 'onChange={this.set_alias} required/>
                        <br/>
                        <label for="response" className="add-command-label">Response</label>
                        <textarea className="add-command-input" id="response" name="redemption_rate" cols="50" rows="5" maxlength="250" placeholder='Command response' onChange={this.set_response} required/>
                        <label for="enable" className="dig-settings-label" required>Mod Only</label>
                        <div name="enable">
                            <input type="radio" className="dig-settings-input" id="form-enable" name="enable" onChange={this.set_mod_only} value='on' checked={enabled_radio_true}></input>
                            <label className="radio-labels">Enable</label>
                            <input type="radio" className="dig-settings-input" id="form-disable" name="enable" onChange={this.set_mod_only} value='off' checked={enabled_radio_false}></input>
                            <label className="radio-labels">Disable</label>
                        </div>
                        <button type="submit" className="add-command-form-button">Save Command</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddCommand;