import axios from 'axios';
import react from 'react';
import './edit_pools.css';
import Login from './login'

class EditPool extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        pool_name: this.props.pool_object.pool_name,
        title: this.props.pool_object.pool_name,
        redemption_rate: this.props.pool_object.redemption_rate,
        pool_id: this.props.pool_object.pool_id,
        token: this.props.pool_object.token,
        alert: {}
      }
      this.set_pool_name = this.set_pool_name.bind(this);
      this.set_redemption_rate = this.set_redemption_rate.bind(this);
      this.save_token = this.save_token.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_pools = this.pass_pools.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
    }

    set_pool_name (e) {
        this.setState({pool_name: e.target.value});
    }

    set_redemption_rate (e) {
        this.setState({redemption_rate: e.target.value});
    }

    save_token (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/edit_pool', {
            pool_name: this.state.pool_name,
            pool_id: this.state.pool_id,
            redemption_rate: this.state.redemption_rate,
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.pass_alert();
                this.pass_pools();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_pools () {
        this.props.pass_pools();
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }

    render () {
        return (
            <div className="edit-pool-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="edit-pool-title main-title">Edit Pool: {this.state.title}</h3>
                <div className="edit-pool-form">
                    <form id="edit-pool-form" onSubmit={this.save_token}>
                        <label for="pool_name" className="pool-label">Pool Name: </label>
                        <input id="pool_name" className="pool-input" type="text" name="pool_name" placeholder={this.state.pool_name} value={this.state.pool_name} onChange={this.set_pool_name} required/>
                        <br/>
                        <label for="redemption_rate" className="pool-label">Redemption Rate</label>
                        <input type="number" className="pool-input" id="redemption_rate" name="redemption_rate" min="1" max="100000" placeholder={this.state.redemption_rate} value={this.state.redemption_rate} onChange={this.set_redemption_rate} required/>
                        <button type="submit" className="pool-form-button">Save Pool</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default EditPool;