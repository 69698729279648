import axios from 'axios';
import react from 'react';
import './chests.css';
import Login from './login'
import Refresh from './refresh';

class Chests extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        chest_tokens: 5,
        spawn_rate: 30,
        maths_sum: 'on',
        enabled: 'on',
        alert: {}
      }
      this.set_chest_tokens = this.set_chest_tokens.bind(this);
      this.set_spawn_rate = this.set_spawn_rate.bind(this);
      this.set_maths_sum = this.set_maths_sum.bind(this);
      this.save_chest_settings = this.save_chest_settings.bind(this);
      this.set_enabled = this.set_enabled.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_chests = this.load_chests.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_chests();
    }

    load_chests () {
        axios.get('https://scurvybot.com/api/chest_settings')
        .then((res) => {
            let maths_sum = res.data.math_sum === 1 ? 'on' : 'off';
            let enabled = res.data.enabled === 1 ? 'on' : 'off';
            this.setState({chests_tokens: res.data.amount, spawn_rate: res.data.spawn_rate, maths_sum: maths_sum, enabled: enabled})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.pass_alert({type: 'failed', message: err.response.data.message})
            }
          });
    }

    set_chest_tokens (e) {
        this.setState({chest_tokens: e.target.value})
    }

    set_spawn_rate (e) {
        this.setState({spawn_rate: e.target.value})
    }

    set_maths_sum (e) {
        this.setState({maths_sum: e.target.value})
    }

    set_enabled (e) {
        this.setState({enabled: e.target.value})
    }

    save_chest_settings (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/save_chest_settings', {
            amount: this.state.chest_tokens,
            spawn_rate: this.state.spawn_rate,
            math_sum: this.state.maths_sum,
            enabled: this.state.enabled
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.load_chests()
                this.pass_alert();
            })
        })
        .catch((err) => {
            alert(err)
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    refresh_page () {
        this.load_chests();
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let chest_tokens = this.state.chest_tokens ? this.state.chest_tokens : 1;
        let spawn_rate = this.state.spawn_rate ? this.state.spawn_rate : 30;
        let maths_radio_on = this.state.maths_sum === 'on' ? true : false;
        let maths_radio_off = this.state.maths_sum === 'on' ? false : true;
        let enabled_radio_true = this.state.enabled === 'on' ? true : false;
        let enabled_radio_false = this.state.enabled === 'on' ? false : true;

        return (
            <div className="chests-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="chests-title main-title">Chest Settings</h3>
                <div className="chests-form">
                    <form id="chests-form" onSubmit={this.save_chest_settings}>
                        <label for="chest-tokens" className="chest-settings-label">Tokens Per Chest: </label>
                        <input id="chest-tokens" className="chest-settings-input" type="number" name="chest-tokens" min="1" placeholder={chest_tokens} value={this.state.chest_tokens} onChange={this.set_chest_tokens} required/>
                        <br/>
                        <label for="spawn-rate" className="chest-settings-label">Spawn Rate (Minutes): </label>
                        <input type="number" className="chest-settings-input" id="spawn-rate" name="spawn-rate" min="1" max="60" placeholder={spawn_rate} value={this.state.spawn_rate} onChange={this.set_spawn_rate} required/>
                        <br/>
                        <label for="maths-sum" className="chest-settings-label" required>Maths Sum: </label>
                        <div name="maths-sum">
                            <input type="radio" className="chest-settings-input" id="maths-sum-on" name="maths-sum" onChange={this.set_maths_sum} value='on' checked={maths_radio_on}></input>
                            <label className="radio-labels">On</label>
                            <input type="radio" className="chest-settings-input" id="maths-sum-off" name="maths-sum" onChange={this.set_maths_sum} value='off' checked={maths_radio_off}></input>
                            <label className="radio-labels">Off</label>
                        </div>
                        <br/>
                        <label for="enable" className="chest-settings-label" required>Enable</label>
                        <div name="enable">
                            <input type="radio" className="chest-settings-input" id="form-enable" name="enable" onChange={this.set_enabled} value='on' checked={enabled_radio_true}></input>
                            <label className="radio-labels">Enable</label>
                            <input type="radio" className="chest-settings-input" id="form-disable" name="enable" onChange={this.set_enabled} value='off' checked={enabled_radio_false}></input>
                            <label className="radio-labels">Disable</label>
                        </div>
                        <button type="submit" className="token-form-button">Save Chest Settings</button>
                    </form>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Chests;