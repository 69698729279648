import axios from 'axios';
import react from 'react';
import './channel_token.css';
import Login from './login'
import Refresh from './refresh';

class Channel_token extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        token_id: null,
        username: '',
        token_name: null,
        viewer_earnings: null,
        subscription_bonus: null,
        bits_bonus: null,
        chatter_bonus: null,
        subscriber_bonus: null,
        alert: {}
      }
      this.pass_alert = this.pass_alert.bind(this);
      this.set_token_name = this.set_token_name.bind(this);
      this.set_viewer_earnings = this.set_viewer_earnings.bind(this);
      this.set_subscription_bonus = this.set_subscription_bonus.bind(this);
      this.set_bits_bonus = this.set_bits_bonus.bind(this);
      this.set_chatter_bonus = this.set_chatter_bonus.bind(this);
      this.set_subscriber_bonus = this.set_subscriber_bonus.bind(this);
      this.save_token = this.save_token.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_channel_token = this.load_channel_token.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_channel_token();
    }

    load_channel_token () {
        axios.get('https://scurvybot.com/api/channel_token')
        .then((res) => {
            this.setState({token_id: res.data.token_id, token_name: res.data.token_name, viewer_earnings: res.data.view_rate, subscription_bonus: res.data.sub_rate, subscriber_bonus: res.data.sub_bonus, chatter_bonus: res.data.chatter_bonus, bits_bonus: res.data.bits_rate})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    set_token_name (e) {
        this.setState({token_name: e.target.value});
    }

    set_viewer_earnings (e) {
        this.setState({viewer_earnings: e.target.value})
    }

    set_subscription_bonus (e) {
        this.setState({subscription_bonus: e.target.value});
    }

    set_bits_bonus (e) {
        this.setState({bits_bonus: e.target.value});
    }

    set_chatter_bonus (e) {
        this.setState({chatter_bonus: e.target.value});
    }

    set_subscriber_bonus (e) {
        this.setState({subscriber_bonus: e.target.value});
    }

    save_token (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/save_channel_token', {
            token_name: this.state.token_name,
            sub_rate: this.state.subscription_bonus,
            bits_rate: this.state.bits_bonus,
            view_rate: this.state.viewer_earnings,
            sub_bonus: this.state.subscriber_bonus,
            chatter_bonus: this.state.chatter_bonus
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.load_channel_token()
                this.pass_alert();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }

    refresh_page () {
        this.load_channel_token();
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let token_name = this.state.token_name ? this.state.token_name : this.state.username;
        let viewer_earnings = this.state.viewer_earnings ? this.state.viewer_earnings : 1;
        let subscription_bonus = this.state.subscription_bonus ? this.state.subscription_bonus : 0;
        let bits_bonus = this.state.bits_bonus ? this.state.bits_bonus : 0;
        let chatter_bonus = this.state.chatter_bonus ? this.state.chatter_bonus : 0;
        let subscriber_bonus = this.state.subscriber_bonus ? this.state.subscriber_bonus : 0;

        return (
            <div className="channel-token-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="stream-token-title main-title">Your stream token</h3>
                <div className="stream-token-form">
                    <form id="token-form" onSubmit={this.save_token}>
                        <label for="token_name" className="token-label">Token Name: </label>
                        <input id="token_name" className="token-input" type="text" name="token_name" placeholder={token_name} value={this.state.token_name} onChange={this.set_token_name} required/>
                        <br/>
                        <label for="view_earnings" className="token-label">Tokens earned per 10 minutes of viewing: </label>
                        <input type="number" className="token-input" id="view_earnings" name="view_earnings" min="0" max="1000000" placeholder={viewer_earnings} value={this.state.viewer_earnings} onChange={this.set_viewer_earnings} required/>
                        <br/>
                        <label for="subscription_bonus" className="token-label">Tokens earnt per subscription: </label>
                        <input type="number" className="token-input" id="subscription_bonus" name="subscription_bonus" min="0" max="1000000" placeholder={subscription_bonus} value={this.state.subscription_bonus} onChange={this.set_subscription_bonus} required/>
                        <br/>
                        <label for="bits_bonus" className="token-label">Tokens earnt per 100 bits: </label>
                        <input type="number" className="token-input" id="bits_bonus" name="bits_bonus" min="0" max="1000000" placeholder={bits_bonus} value={this.state.bits_bonus} onChange={this.set_bits_bonus} required/>
                        <br/>
                        <label for="subscriber_bonus" className="token-label">Subscriber bonus earnings (per 10 minutes): </label>
                        <input type="number" className="token-input" id="subscriber_bonus" name="subscriber_bonus" min="0" max="1000000" placeholder={subscriber_bonus} value={this.state.subscriber_bonus} onChange={this.set_subscriber_bonus} required/>
                        <br/>
                        <label for="chatter_bonus" className="token-label">Chatter bonus earnings (per 10 minutes): </label>
                        <input type="number" className="token-input" id="chatter_bonus" name="chatter_bonus" min="0" max="1000000" placeholder={chatter_bonus} value={this.state.chatter_bonus} onChange={this.set_chatter_bonus} required/>
                        <button type="submit" className="token-form-button">Save Token</button>
                    </form>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Channel_token;