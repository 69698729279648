import react from 'react';
import './sidebar.css'
import SubmenuPools from './pools-submenu';
import SubmenuTools from './tools-submenu';
import SubmenuLoyalty from './loyalty-submenu';


class Sidebar extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        submenu_pools: false,
        submenu_tools: false,
        submenu_loyalty: false,
        menu_items: ['pools', 'tools', 'loyalty', 'settings', 'support'],
        sub_menu_active: false,
      }
      this.drop_submenu_pools = this.drop_submenu_pools.bind(this);
      this.drop_submenu_tools = this.drop_submenu_tools.bind(this);
      this.drop_submenu_loyalty = this.drop_submenu_loyalty.bind(this);
      this.channel_token = this.channel_token.bind(this);
      this.leaderboard = this.leaderboard.bind(this);
      this.view_pools = this.view_pools.bind(this);
      this.create_pool = this.create_pool.bind(this);
      this.view_features = this.view_features.bind(this);
      this.view_commands = this.view_commands.bind(this);
      this.view_timed_commands = this.view_timed_commands.bind(this);
      this.drop_submenu_settings = this.drop_submenu_settings.bind(this);
      this.drop_submenu_support = this.drop_submenu_support.bind(this);
      this.view_transactions = this.view_transactions.bind(this);
      this.banned = this.banned.bind(this);
      this.reset_menu = this.reset_menu.bind(this);
      this.tip = this.tip.bind(this);
    }

    drop_submenu_pools () {
        let status = this.state.submenu_pools ? false : true;
        this.setState({submenu_pools: status, submenu_tools: false, submenu_loyalty: false});
        let items = this.state.menu_items;
        let index = items.findIndex((x) => x === 'pools');
        let item = items[index];
        items.splice(index, 1)
        this.add_classes(item, items);
    }

    drop_submenu_tools () {
        let status = this.state.submenu_tools ? false : true;
        this.setState({submenu_tools: status, submenu_pools: false, submenu_loyalty: false});
        let items = this.state.menu_items;
        let index = items.findIndex((x) => x === 'tools');
        let item = items[index];
        items.splice(index, 1)
        this.add_classes(item, items);
    }

    drop_submenu_loyalty () {
        let status = this.state.submenu_loyalty ? false : true;
        this.setState({submenu_loyalty: status, submenu_pools: false, submenu_tools: false});
        let items = this.state.menu_items;
        let index = items.findIndex((x) => x === 'loyalty');
        let item = items[index];
        items.splice(index, 1)
        this.add_classes(item, items);
    }

    add_classes (item, items) {
        if (!this.state.sub_menu_active) {
            this.setState({sub_menu_active: true, menu_items: ['pools', 'tools', 'loyalty', 'settings', 'support']}, () => {
                document.getElementsByClassName(item)[0].className += ' menu-item-focused';
                for (let item in items) {
                    document.getElementsByClassName(items[item])[0].className += ' menu-items-hidden';
                }
            })
        } else {
            this.setState({sub_menu_active: false, menu_items: ['pools', 'tools', 'loyalty', 'settings', 'support']}, () => {
                let elements = document.getElementsByClassName('menu-items')
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('menu-items-hidden')
                    elements[i].classList.remove('menu-item-focused')
                }
            })
        }
    }

    channel_token (data) {
        this.props.pass_channel_token(data);
    }

    leaderboard (data) {
        this.props.pass_leaderboard(data);
    }

    view_pools (data) {
        this.props.pass_view_pools(data);
    }

    create_pool (data) {
        this.props.pass_create_pool(data);
    }

    view_features (data) {
        this.props.pass_features(data);
    }

    view_commands (data) {
        this.props.pass_commands(data);
    }

    view_timed_commands (data) {
        this.props.pass_timed_commands(data);
    }

    drop_submenu_settings () {
        this.props.pass_settings();
    }

    drop_submenu_support () {
        this.props.pass_support()
    }


    view_transactions () {
        this.props.pass_transactions();
    }

    banned () {
        this.props.pass_banned();
    }

    tip () {
        this.props.pass_tip();
    }

    reset_menu () {
        this.setState({submenu_pools: false, submenu_tools: false, submenu_loyalty: false, sub_menu_active: false});
    } 

    render () {
        return (
            <div className="sidebar">
                <div className="header">
                    <a href="https://scurvybot.com/"><img className="logo" alt="scurvy-logo" src="./logo.png"/></a>
                    <p className="title">Scurvy Bot</p>
                </div>
                <div className="menu">
                    <div className="pools menu-items">
                        <div className="ellipse menu-images menu-item-content" />
                        <h4 className="menu-item-content" onClick={this.drop_submenu_pools}>Pools</h4>
                        <figure className="arrow-container"  onClick={this.drop_submenu_pools}>
                            <img className="menu-images arrow menu-item-content" alt="arrow indicating a drop down menu option" src="./arrow.png"/>
                        </figure>
                        {this.state.submenu_pools === true && <SubmenuPools reset_menu={this.reset_menu} pass_view_pools={this.view_pools} pass_create_pool={this.create_pool} pass_transactions={this.view_transactions} active_submenu={this.state.sub_menu_active.toString()}/>}
                    </div>
                    <div className="tools menu-items">
                        <img className="menu-images menu-item-content" alt="tools to indicate tools submenu" src="./tools.png"/>
                        <h4 className="menu-item-content" onClick={this.drop_submenu_tools}>Tools</h4>
                        <figure className="arrow-container" onClick={this.drop_submenu_tools}>
                            <img className="menu-images arrow menu-item-content" alt="arrow indicating a drop down menu option" src="./arrow.png"/>
                        </figure>
                        {this.state.submenu_tools === true && <SubmenuTools reset_menu={this.reset_menu} pass_features={this.view_features} pass_commands={this.view_commands} pass_timed_commands={this.view_timed_commands} active_submenu={this.state.sub_menu_active.toString()}/>}
                    </div>
                    <div className="loyalty menu-items">
                        <img className="menu-images menu-item-content" alt="heart to indicate loyalty submenu" src="./heart.png"/>
                        <h4 className="menu-item-content" onClick={this.drop_submenu_loyalty}>Loyalty</h4>
                        <figure className="arrow-container" onClick={this.drop_submenu_loyalty}>
                            <img className="menu-images arrow menu-item-content" alt="arrow indicating a drop down menu option" src="./arrow.png"/>
                        </figure>
                        {this.state.submenu_loyalty === true && <SubmenuLoyalty reset_menu={this.reset_menu} pass_channel_token={this.channel_token} pass_leaderboard={this.leaderboard} pass_banned={this.banned} pass_tip={this.tip} active_submenu={this.state.sub_menu_active.toString()}/>}
                    </div>
                    <div className="settings menu-items">
                        <img className="menu-images menu-item-content" alt="cog to indicate settings submenu" src="./cog.png"/>
                        <h4 className="menu-item-content" onClick={this.drop_submenu_settings} active_submenu={this.state.sub_menu_active.toString()}>Settings</h4>
                    </div>
                    <div className="support menu-items">
                        <img className="menu-images menu-item-content" alt="envelope to indicate support submenu" src="./help.png"/>
                        <h4 className="menu-item-content" onClick={this.drop_submenu_support} active_submenu={this.state.sub_menu_active.toString()}>Support</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;
