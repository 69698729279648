import axios from 'axios';
import react from 'react';
import './edit_command.css';
import Login from './login'

class EditTimedCommands extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        command: this.props.command.command,
        title: this.props.command.command,
        command_interval: this.props.command.command_interval,
        action: this.props.command.action,
        command_id: this.props.command.command_id,
        alert: {}
      }
      this.set_alias = this.set_alias.bind(this);
      this.set_response = this.set_response.bind(this);
      this.set_interval = this.set_interval.bind(this);
      this.save_command = this.save_command.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_timed_commands = this.pass_timed_commands.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
    }
    
    set_alias (e) {
        this.setState({command: e.target.value});
    }

    set_response (e) {
        this.setState({action: e.target.value});
    }

    set_interval (e) {
        this.setState({command_interval: e.target.value});
    }

    save_command (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/edit_timed_command', {
            command_id: this.state.command_id,
            command: this.state.command,
            action: this.state.action,
            interval: this.state.command_interval
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.pass_alert();
                this.pass_timed_commands()
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
      }  

    pass_timed_commands () {
        this.props.pass_timed_commands();
    } 

    pass_logged_in () {
        this.props.pass_credentials();
      }

    render () {
        return (
            <div className="edit-command-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="edit-command-title main-title">Edit Timed Command: {this.state.title}</h3>
                <div className="edit-command-form">
                    <form id="edit-command-form" onSubmit={this.save_command}>
                        <label for="alias" className="edit-command-label">Name: </label>
                        <input id="alias" className="edit-command-input" type="text" name="alias" placeholder={this.state.command} value={this.state.command} onChange={this.set_alias} required/>
                        <br/>
                        <label for="response" className="edit-command-label">Response</label>
                        <textarea className="edit-command-input" id="response" name="response" cols="50" rows="5" maxlength="250" placeholder={this.state.action} value={this.state.action} onChange={this.set_response} required/>
                        <label for="interval" className="edit-command-label">Interval</label>
                        <input id="interval" className="edit-command-input" type="number" min="5" max="60" name="interval" placeholder={this.state.command_interval} value={this.state.command_interval} onChange={this.set_interval} required/>
                        <button type="submit" className="edit-command-form-button">Save Command</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default EditTimedCommands;