import react from 'react';
import './view_pools.css';
import Login from './login'
import axios from 'axios';
import Refresh from './refresh';

class ViewPools extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        view_pools: [],
        deposit_memo: null,
        alert: {}
      }
      this.edit_pools_page = this.edit_pools_page.bind(this);
      this.withdraw_from_pool = this.withdraw_from_pool.bind(this);
      this.delete_pool = this.delete_pool.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_pools = this.load_pools.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
      this.load_pools();
    }

    load_pools () {
      axios.get('https://scurvybot.com/api/view_pools')
      .then((res) => this.setState({view_pools: res.data}))
      .catch((err) => {
          if (err.response.data.code === 301) {
            window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
          } else if (err.response.data.code === 500 || err.response.data.code === 400) {
            this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
              this.pass_alert()
            })
          }
        });
      axios.get('https://scurvybot.com/api/nft_deposit_memo',)
      .then((res) => {
        this.setState({deposit_memo: res.data})
      })
      .catch((err) => {
          if (err.response.data.code === 301) {
            window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
          } else if (err.response.data.code === 500 || err.response.data.code === 400) {
            this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
              this.pass_alert()
            })
          }
        });
    }

    edit_pools_page (e) {
        if (this.state.view_pools.length === 0) {
            return;
        } else {
            this.props.pass_edit_pools({pool_id: e.target.value, pool_name: e.target.name, redemption_rate: e.target.id});
        }
    }

    withdraw_from_pool (e) {
        if (this.state.view_pools.length === 0) {
            return;
        } else {
            this.props.pass_withdraw_from_pool({pool_id: e.target.value, pool_name: e.target.name, token: e.target.id, balance: e.target.title});
        }
    }

    delete_pool (e) {
        e.preventDefault(e);
        if (this.state.view_pools.length === 0) return;
        function verify () {
            return new Promise((resolve, reject) => {
              // eslint-disable-next-line no-restricted-globals
              let confirmed = confirm('Are you sure you want to delete pool ' + e.target.name + '? \nAll remaining tokens will be returned to your assigned wallet.')
              if (confirmed) {
                resolve(true);
              } else {
                reject();
              }
            })
          }
          verify()
          .then(() => {
            axios.post('https://scurvybot.com/api/delete_pool', {
                pool_id: e.target.value
            })
            .then((res) => {
              this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.load_pools();
                this.pass_alert();
              })
            }) 
            .catch((err) => {
              if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls"
              } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                  this.pass_alert()
                })
              }
            });
          })
          .catch((err) => {return})
    }

    pass_logged_in () {
      this.props.pass_credentials();
    }

    pass_alert () {
      this.props.pass_alert(this.state.alert)
    }

    refresh_page () {
      this.load_pools();
      this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
        this.pass_alert()
    })
    }

    render () {
        let view_pools = this.state.view_pools.length >= 1 ? this.state.view_pools : [{pool_name: 'N/A', token_name: 'N/A', balance: 'N/A', redemption_rate: 'N/A', pool_id: 'N/A', link: <button className="deposit action-button">Deposit</button>}];

        return (
            <div className="view_pools-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="view_pools-list">
                    <h3 className="view_pools-title main-title">View Pools</h3>
                    <table className="view_pools-table">
                        <tr className="view_pools-rows">
                            <th className="view_pools-headings">Name</th>
                            <th className="view_pools-headings">Token</th>
                            <th className="view_pools-headings">Bal.</th>
                            <th className="view_pools-headings">Rate</th>
                            <th className="view_pools-headings">Actions</th>
                        </tr>
                        {view_pools.map((x, index) => {
                            x.balance = x.balance.toString().split('')
                            x.balance.length = 7;
                            x.balance = x.balance.join('');
                            if (x.pool_name !== 'N/A') {
                              x.link = <button className="deposit action-button" value={x.pool_id}><a href={`https://wax.atomichub.io/trading/token-transfer?partner=scurvybot.gm&memo=${this.state.deposit_memo + '-' + x.pool_id}`} target="_blank" rel="noreferrer">Deposit</a></button>
                            }
                            return (
                                <tr className="view_pools-rows" key={index}>
                                    <td className="view_pools-datacells">{x.pool_name}</td>
                                    <td className="view_pools-datacells">{x.token}</td>
                                    <td className="view_pools-datacells">{x.balance}</td>
                                    <td className="view_pools-datacells">{x.redemption_rate}</td>
                                    <td className="view_pools-datacells actions">
                                        {x.link}
                                        <button className="withdraw action-button" value={x.pool_id} name={x.pool_name} id={x.token} title={x.balance} onClick={this.withdraw_from_pool}>Withdraw</button>
                                        <button className="edit action-button" value={x.pool_id} name={x.pool_name} id={x.redemption_rate} for={x.token} onClick={this.edit_pools_page}>Edit</button>
                                        <button className="delete action-button" value={x.pool_id} name={x.pool_name} onClick={this.delete_pool}>Delete</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default ViewPools;