import react from 'react';
import './settings.css';
import Login from './login'
import axios from 'axios';
import Refresh from './refresh';

class Settings extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        wallet: null,
        email: null,
        email_sign_up: 'on',
        alert: {},
        subscribed: false,
        expiry: null,
        cost: null,
        channel_id: null
      }
      this.update_wallet = this.update_wallet.bind(this);
      this.update_email = this.update_email.bind(this);
      this.update_email_confirmations = this.update_email_confirmations.bind(this);
      this.save_settings = this.save_settings.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_account_settings = this.load_account_settings.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
      this.load_subscription_data = this.load_subscription_data.bind(this);
    }

    componentDidMount () {
        this.load_account_settings();
        this.load_subscription_data();
    }

    load_account_settings () {
        axios.get('https://scurvybot.com/api/account_settings')
        .then((res) => {
            let emails_enabled = res.data.emails_enabled === 1 ? 'on' : 'off';
            this.setState({wallet: res.data.wallet, email_sign_up: emails_enabled, email: res.data.email})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    load_subscription_data () {
        // Get subscription status first, so can end calls if so.
        axios.get('https://scurvybot.com/api/premium_status')
        .then((res) => {
            if (res.data.status) {
                this.setState({subscribed: true, expiry: res.data.expiry})
                return;
            }
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
        axios.get('https://scurvybot.com/api/account_data')
        .then((res) => {this.setState({channel_id: res.data.user_id})})
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        })
        axios.get('https://wax.alcor.exchange/api/v2/tokens')
        .then((res) => {
            let wax_index = res.data.findIndex((x) => x.symbol === 'WAX');
            let cost = 5 / res.data[wax_index].usd_price
            this.setState({cost: Math.ceil(cost)})
        })
        .catch((err) => {
            this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                this.pass_alert()
            })
        });
    }

    update_wallet (e) {
        this.setState({wallet: e.target.value})
    }

    update_email (e) {
        this.setState({email: e.target.value})
    }

    update_email_confirmations (e) {
        this.setState({email_sign_up: e.target.value})
    }

    save_settings (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/save_account_settings', {
            email: this.state.email,
            wallet: this.state.wallet,
            enabled: this.state.email_sign_up
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.load_account_settings()
                this.pass_alert();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }

    refresh_page () {
        this.load_account_settings();
        this.load_subscription_data();
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let enabled_radio_true = this.state.email_sign_up === 'on' ? true : false;
        let enabled_radio_false = this.state.email_sign_up === 'on' ? false : true;
        let premium = this.state.subscribed ? <p className="subscription_text">Account status: <span className="premium_status">Premium expires on {this.state.expiry}</span></p> : <p className="subscription_text">Account status: <a href={`https://atomichub.io/trading/token-transfer/?memo=subone-${this.state.channel_id}&partner=ish.gm&quantity=${this.state.cost}`} target="_blank" id="subscription_link">Subscribe now with {this.state.cost} WAX!</a></p>;
        return (
            <div className="settings-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="settings-title main-title">Settings</h3>
                <div className="settings-form">
                    <form id="settings-form" onSubmit={this.save_settings}>
                        <label for="withdraw_name" className="withdraw-label">Withdraw Wallet: </label>
                        <input id="withdraw_name" className="withdraw-input" type="text" name="withdraw_name" placeholder={this.state.wallet} onChange={this.update_wallet} required/>
                        <br/>
                        <label for="redemption_rate" className="withdraw-label">Email: </label>
                        <input type="email" className="withdraw-input" id="redemption_rate" name="redemption_rate" placeholder={this.state.email} onChange={this.update_email}/>
                        <br/>
                        <label for="enable" className="withdraw-label">Sign up to emails (Subscription reminders, transaction notifications, empty pools etc.)</label>
                        <div name="enable">
                            <input type="radio" className="dig-settings-input" id="form-enable" name="enable" onChange={this.update_email_confirmations} value='on' checked={enabled_radio_true}></input>
                            <label className="radio-labels">Yes</label>
                            <input type="radio" className="dig-settings-input" id="form-disable" name="enable" onChange={this.update_email_confirmations} value='off' checked={enabled_radio_false}></input>
                            <label className="radio-labels">No</label>
                        </div>
                        {premium}
                        <button type="submit" className="withdraw-form-button">Save Settings</button>
                    </form>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Settings;