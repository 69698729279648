import axios from 'axios';
import react from 'react';
import './commands.css';
import Login from './login'
import Refresh from './refresh';

class Commands extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        commands: [],
        current_page: 1,
        alert: {}
      }
      this.next_page = this.next_page.bind(this);
      this.previous_page = this.previous_page.bind(this);
      this.edit_command = this.edit_command.bind(this);
      this.add_new_command = this.add_new_command.bind(this);
      this.delete_command = this.delete_command.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_commands();
    }

    load_commands () {
        axios.get('https://scurvybot.com/api/commands')
        .then((res) => {
            this.setState({commands: res.data});
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
            window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
        });
    }

    add_new_command () {
        this.props.display_add_command();
    }
    
    next_page () {
        let current_page = this.state.current_page;
        if (current_page === Math.ceil(this.state.commands.length / 6)) {
            return;
        } else {
            current_page++;
            this.setState({current_page: current_page})
        }
    }

    previous_page () {
        let current_page = this.state.current_page;
        if (current_page === 1) {
            return;
        } else {
            current_page--;
            this.setState({current_page: current_page})
        }
    }

    delete_command (e) {
        e.preventDefault();
        function verify () {
            return new Promise((resolve, reject) => {
              // eslint-disable-next-line no-restricted-globals
              let confirmed = confirm('Delete command: ' + e.target.value)
              if (confirmed) {
                resolve(true);
              } else {
                reject();
              }
            })
          }
          verify()
          .then(() => {
            axios.post('https://scurvybot.com/api/delete_command', {
                command_id: e.target.name
            })
            .then((res) => {
                this.load_commands();
                this.setState({alert: {bool: true, type: 'success', message: res.data.message}, current_page: 1}, () => {
                    this.load_commands();
                    this.pass_alert();
                })
            })
            .catch((err) => {
                alert(err)
                if (err.response.data.code === 301) {
                  window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
                } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                    this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                        this.pass_alert()
                    })
                }
              });
          })
          .catch(() => {
            return;
          })
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    edit_command (e) {
        let mod_only = e.target.title === '1' ? 'on' : 'off';
        this.props.display_edit_command({command: e.target.value, action: e.target.name, command_id: e.target.id, mod_only: mod_only}) // Passes straight into main
    }

    pass_logged_in () {
        this.props.pass_credentials();
      }
    
    refresh_page () {
        this.load_commands();
        this.setState({alert: {bool: true, type: 'success', message: 'Page state updated'}}, () => {
            this.pass_alert()
        })
    }

    render () {
        let commands = this.state.commands;
        let current_page = commands.slice((this.state.current_page-1) * 6, (this.state.current_page * 6))
        let backwards = this.state.commands.length > 6 ? <button className="traversal-buttons previous" onClick={this.previous_page}>{'<< Previous Page'}</button> : null;
        let forwards = this.state.commands.length > 6 ? <button className="traversal-buttons next" onClick={this.next_page}>{'Next Page >>'}</button> : null;


        return (
            
            <div className="commands-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <div className="commands-list">
                    <h3 className="commands-title main-title">Commands</h3>
                    <button id="add_command_button" onClick={this.add_new_command}>+ Add Command</button>
                    <table className="commands-table">
                        <tr className="commands-rows">
                            <th className="commands-headings">Alias</th>
                            <th className="commands-headings">Response</th>
                            <th className="commands-headings">Mod Only</th>
                            <th className="commands-headings">Actions</th>
                        </tr>
                        {current_page.map((x, index) => {
                            let actions = x.default ? <td className="commands-datacells commands-actions"></td> : <td className="commands-datacells commands-actions"><button value={x.command} name={x.action} id={x.command_id} title={x.mod_only} onClick={this.edit_command} className="edit-button">Edit</button><button className="delete-button" value={x.command} name={x.command_id} onClick={this.delete_command}>Delete</button></td>
                            let mod_only = x.mod_only === 1 ? 'Yes' : 'No';
                            let command_prefix = x.default ? x.action : x.action.substring(0, 10) + '...';
                            return (
                                <tr className="commands-rows" key={index}>
                                    <td className="commands-datacells">{x.command}</td>
                                    <td className="commands-datacells">{command_prefix}</td>
                                    <td className="commands-datacells">{mod_only}</td>
                                    {actions}
                                </tr>
                            )
                        })}
                    </table>
                    <div className="commands-traversal-button-container">
                        {backwards}
                        {forwards}
                    </div>
                </div>
                <Refresh pass_click={this.refresh_page}></Refresh>
            </div>
        )
    }
}

export default Commands;