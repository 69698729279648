import axios from 'axios';
import react from 'react';
import './refresh.css';

class Refresh extends react.Component {
    constructor (props) {
      super(props);
      this.pass_click = this.pass_click.bind(this);
    }

    pass_click () {
        this.props.pass_click();
    }
    

    render () {
      return (
        <div id="refresh_parent">
            <button onClick={this.pass_click} id="refresh_button"><img src="./refresh.png" alt="A circular arrow indicating a refresh button. Refreshes the state of the page." id="refresh_icon"/></button>
        </div>
      )
    }
}

export default Refresh;

