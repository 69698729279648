import react from 'react';
import './pools-submenu.css';

class SubmenuPools extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        active_submenu: this.props.active_submenu
      }
      this.display_view_pools = this.display_view_pools.bind(this);
      this.display_create_pool = this.display_create_pool.bind(this);
      this.display_transactions = this.display_transactions.bind(this);
      this.reset_menu = this.reset_menu.bind(this);
    }

    display_view_pools () {
        this.props.pass_view_pools(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_create_pool () {
        this.props.pass_create_pool(true);
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    display_transactions () {
        this.props.pass_transactions()
        let elements = document.getElementsByClassName('menu-items');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('menu-items-hidden')
            elements[i].classList.remove('menu-item-focused')
        }
        if (this.state.active_submenu) {
            this.reset_menu();
        }
    }

    reset_menu () {
        this.props.reset_menu();
    } 


    render () {
        return (
            <div className="sub-menus">
                <div className="create-pool sub-menu-items">
                    <h4 className="sub-menu-item-content" onClick={this.display_create_pool}>Create Pools</h4>
                </div>
                <div className="view-pools sub-menu-items" >
                    <h4 className="sub-menu-item-content" onClick={this.display_view_pools}>View Pools</h4>
                </div>
                <div className="view-pools sub-menu-items" >
                    <h4 className="sub-menu-item-content" onClick={this.display_transactions}>Transactions</h4>
                </div>
            </div>
        )
    }
}

export default SubmenuPools;