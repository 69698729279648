import axios from 'axios';
import react from 'react';
import './shoutouts.css';
import Login from './login'
import Refresh from './refresh';

class Shoutout extends react.Component {
    constructor (props) {
      super(props);
      this.state = {
        action: 'Checkout {user} at {link}',
        enabled: 'on',
        alert: {}
      }
      this.set_action = this.set_action.bind(this);
      this.save_shoutout_settings = this.save_shoutout_settings.bind(this);
      this.set_enabled = this.set_enabled.bind(this);
      this.pass_logged_in = this.pass_logged_in.bind(this);
      this.load_shoutout_settings = this.load_shoutout_settings.bind(this);
      this.pass_alert = this.pass_alert.bind(this);
      this.refresh_page = this.refresh_page.bind(this);
    }

    componentDidMount () {
        this.load_shoutout_settings();
    }

    load_shoutout_settings () {
        axios.get('https://scurvybot.com/api/shoutout_settings')
        .then((res) => {
            let enabled = res.data.enabled === 1 ? 'on' : 'off';
            this.setState({action: res.data.action, enabled: enabled})
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
              this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                this.pass_alert()
              })
            }
          });
    }

    set_action (e) {
        this.setState({action: e.target.value})
    }

    set_enabled (e) {
        this.setState({enabled: e.target.value})
    }

    save_shoutout_settings (e) {
        e.preventDefault();
        axios.post('https://scurvybot.com/api/save_shoutout_settings', {
            action: this.state.action,
            enabled: this.state.enabled
        })
        .then((res) => {
            this.setState({alert: {bool: true, type: 'success', message: res.data.message}}, () => {
                this.load_shoutout_settings();
                this.pass_alert();
            })
        })
        .catch((err) => {
            if (err.response.data.code === 301) {
              window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=mhq0anu6pdqbidyp581zga8slrh0t4&redirect_uri=https://scurvybot.com/api/authenticate&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters"
            } else if (err.response.data.code === 500 || err.response.data.code === 400) {
                this.setState({alert: {bool: true, type: 'failed', message: err.response.data.message}}, () => {
                    this.pass_alert()
                })
            }
          });
    }

    pass_alert () {
        this.props.pass_alert(this.state.alert)
    }

    pass_logged_in () {
        this.props.pass_credentials();
    }

    refresh_page () {
        this.load_shoutout_settings();
    }

    render () {
        let action = this.state.action === 'Checkout {user} at {link}' ? 'Called with !so user or !shoutout user. Use {user} and {link} in your message.' : this.state.action;
        let enabled_radio_true = this.state.enabled === 'on' ? true : false;
        let enabled_radio_false = this.state.enabled === 'on' ? false : true;

        return (
            <div className="shoutout-page">
                <div className='login-out-component'>
                    <Login pass_logged_in={this.pass_logged_in}/>
                </div>
                <h3 className="shoutout-title main-title">Shoutout Settings</h3>
                <div className="shoutout-form">
                    <form id="shoutout-form" onSubmit={this.save_shoutout_settings}>
                        <label for="action" className="shoutout-settings-label">Shoutout message: (variables: &#123;user&#125; and &#123;link&#125;)</label>
                        <input type="text" className="shoutout-settings-input" id="action" name="action" placeholder={action} value={this.state.action} onChange={this.set_action} required/>
                        <br/>
                        <label for="enable" className="shoutout-settings-label" required>Enable</label>
                        <div name="enable">
                            <input type="radio" className="shoutout-settings-input" id="form-enable" name="enable" onChange={this.set_enabled} value='on' checked={enabled_radio_true}></input>
                            <label className="radio-labels">Enable</label>
                            <input type="radio" className="shoutout-settings-input" id="form-disable" name="enable" onChange={this.set_enabled} value='off' checked={enabled_radio_false}></input>
                            <label className="radio-labels">Disable</label>
                        </div>
                        <button type="submit" className="token-form-button">Save Shoutout Settings</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Shoutout;